import React, { useState } from "react";
import { FormInput } from "../../global/formInput";
import { Wrapper, Box, InputBox, ButtonBox, FirstWrapper } from "./style";
import { CustomButton } from "../../global/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { resume } from "../../../state/resume";
import { trackPageView } from "../../../utils/analytics";
const Information = () => {
  const location = useLocation();
  trackPageView("resume-personal-information", location.pathname);
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  trackPageView("resume-personal-information", location.pathname);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [bio, setBio] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const setObject = useSetRecoilState(resume);
  const object = useRecoilValue(resume);
  const handleSubmit = () => {
    setLoading(true);
    setObject({
      ...object,
      fullName: fullName,
      role: role,
      email: email,
      telephone: telephone,
      bio: bio,
      linkedin: linkedin,
      websiteUrl: websiteUrl,
    });
    navigate("/dashboard/resume/create/education");
    setLoading(false);
  };
  return (
    <Wrapper>
      <Box>
        <h1 className="heading">Personal information</h1>
        <p className="sub-text">
          Fill the form below as clearly as possible to generate a suitable
          resume
        </p>
      </Box>
      <FirstWrapper>
        <InputBox>
          <FormInput
            label="Full name"
            index="1"
            type="text"
            value={fullName}
            className="left"
            stateHandler={setFullName}
            holder="e.g Peter Pan"
          />
          <FormInput
            label="Job title"
            index="2"
            type="text"
            value={role}
            className="right"
            stateHandler={setRole}
            holder="e.g Product Designer"
          />
        </InputBox>
        <InputBox>
          <FormInput
            label="Email address"
            index="3"
            type="email"
            value={email}
            stateHandler={setEmail}
            holder="name@example.com"
            className="left"
          />
          <FormInput
            label="Phone number"
            index="4"
            type="text"
            value={telephone}
            stateHandler={setTelephone}
            className="right"
            holder="e.g +234 812 345 6789"
          />
        </InputBox>
        <InputBox>
          <FormInput
            label="LinkedIn URL"
            index="5"
            type="text"
            value={linkedin}
            stateHandler={setLinkedin}
            holder="https://www.linkedin.com/"
            className="left"
          />
          <FormInput
            label="Portfolio/Website URL"
            index="6"
            type="text"
            value={websiteUrl}
            stateHandler={setWebsiteUrl}
            className="right"
            holder="https://www.yourdomain.com/"
          />
        </InputBox>
        <FormInput
          label="Short bio"
          index="7"
          type="textarea"
          value={bio}
          stateHandler={setBio}
          holder="Write something interesting..."
        />
        <ButtonBox>
          <CustomButton
            // disabled={!fullName || !role || !email || !telephone}
            loading={loading}
            onClick={handleSubmit}
          >
            Continue
          </CustomButton>
        </ButtonBox>
      </FirstWrapper>
    </Wrapper>
  );
};

export default Information;
