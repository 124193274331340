import React, { useEffect, useState } from "react";
import { Wrapper, LogoBox, ContentBox, Form, SubText } from "../style";
import { Google, Logo, HandShake } from "../../../assets";
import { GoogleButton, LoginButton } from "../../global/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { setLocalUserInstance } from "../../../state/localstorage";
import moment from "moment";
import { GOOGLE_SHEET_URL } from "../../../config/config";
import { alerts } from "../../../utils/alert";
import { FormInput } from "../../global/formInput";
import { useMutation } from "react-query";
import { createUser, loginGoogleUser } from "../../../services/authService";
import { useLocalStorage } from "react-use";
import { useAuth } from "../../../providers/AuthProvider";
import {
  ActivityLabels,
  trackButtonClick,
  trackPageView,
} from "../../../utils/analytics";
const SignUp = () => {
  const location = useLocation();
  trackPageView("signup", location.pathname);
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const [user, setUser] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [googleloading, setGoogleLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useLocalStorage<any>("token");
  const signup = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });
  const SignUp = useMutation(createUser, {
    onSuccess: (data) => {
      if (data.accessToken) {
        setToken(data.accessToken);
        setAuth({ access_token: "true" });
        trackButtonClick(ActivityLabels.SIGN_UP);
        alerts.success("Login Success", "Login successful");
        // window.location.replace("/dashboard");
        navigate("/dashboard");
      } else {
        alerts.success("Signup Success", data.message);
        navigate(`/auth/verify-email/${email}`);
      }
    },
    onError: (error: any) => {
      setLoading(false);
      setGoogleLoading(false);
      alerts.error("Signup Failed", error);
    },
  });
  useEffect(() => {
    if (user.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res: any) => {
          setGoogleLoading(true);
          setLocalUserInstance(res.data);
          onSignUp(user.access_token);
        })
        .catch((err: any) => console.log(err));
    }
  }, [user]);
  const onSignUp = (token: any) => {
    GoogleLogin.mutate({
      accessToken: token,
    });
  };
  const GoogleLogin = useMutation(loginGoogleUser, {
    onSuccess: (data: any) => {
      setToken(data.accessToken);
      setAuth({ access_token: "true" });
      trackButtonClick(ActivityLabels.SIGN_UP);
      alerts.success("Login Success", "Login successful");
      // window.location.replace("/dashboard/home");
      navigate("/dashboard");
    },
    onError: (error: any) => {
      setLoading(false);
      setGoogleLoading(false);
      if (
        error ===
        "Email not verified. Verification email has been sent to your email"
      ) {
        navigate(`/auth/verify-email/${email}`);
      }
      alerts.error("Login Failed", error);
    },
  });
  const onSubmit = () => {
    if (firstName.length < 2 || lastName.length < 2) {
      alerts.error("Error", "Full Name must contain at least 2 letters.");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alerts.error("Error", "Invalid email address.");
      return;
    }

    if (password.length < 6) {
      alerts.error("Error", "Password must contain at least 6 characters.");
      return;
    }
    setLoading(true);
    SignUp.mutate({
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
    });
  };
  return (
    <Wrapper>
      <LogoBox>
        <img src={Logo} alt="logo" />
      </LogoBox>
      <ContentBox>
        <img src={HandShake} alt="logo" />
        <div className="box">
          <h1 className="text">Let’s get you started</h1>
          <p className="sub-text">
            Create an account today and get started for free
          </p>
        </div>
        <Form>
          <FormInput
            label="First Name"
            type="text"
            value={firstName}
            stateHandler={setFirstName}
            holder="e.g Peter Pan"
          />
          <FormInput
            label="Last Name"
            type="text"
            value={lastName}
            stateHandler={setLastName}
            holder="e.g Peter Pan"
          />
          <FormInput
            label="Email address"
            type="email"
            value={email}
            stateHandler={setEmail}
            holder="name@example.com"
          />
          <FormInput
            label="Password"
            type="password"
            value={password}
            stateHandler={setPassword}
            holder="*********"
          />
        </Form>
        <div className="buttons">
          <GoogleButton
            onClick={onSubmit}
            disabled={!password || !email || !firstName || !lastName}
            loading={loading}
          >
            Sign up
          </GoogleButton>
          <SubText>
            <span className="span-new">or</span>
          </SubText>
          <LoginButton onClick={() => signup()}>
            <img src={Google} alt="retry" />
            Sign up with Google
            {googleloading && <i className="fa fa-spinner fa-spin"></i>}
          </LoginButton>
          <SubText>
            Already have an account?{" "}
            <Link to="/auth/login">
              <span className="span"> Log in</span>
            </Link>
          </SubText>
        </div>
      </ContentBox>
    </Wrapper>
  );
};

export default SignUp;
