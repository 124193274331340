import React, { ReactNode } from "react";
import Header from "./navbar";
import Footer from "./footer";
import Navbar from "./mobile-navbar";
import { useMedia } from "react-use";

interface LayoutProps {
  children: ReactNode;
}
const Layout = ({ children }: LayoutProps) => {
  const isSmallScreen = useMedia("(max-width: 768px)", true);
  return (
    <>
      {isSmallScreen ? <Navbar /> : <Header />}
      {children}
      <Footer />
    </>
  );
};

export default Layout;
