import styled, { keyframes } from "styled-components";
const Wrapper = styled.div`
  padding: 58px 64px;
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    padding: 5px 16px;
  }
  a {
    text-decoration: none;
    width: 48%;
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      width: 100%;
    }
  }
`;
const Box = styled.div`
  .heading {
    font-family: "Montreal";
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.x24};
    line-height: 30px;
    color: ${({ theme }) => theme.colors.grey6};
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      font-size: ${({ theme }) => theme.fontSize.x20};
    }
  }
  .sub-text {
    font-family: "Montreal";
    font-style: normal;
    font-weight: 400;
    padding-top: 8px;
    font-size: ${({ theme }) => theme.fontSize.x16};
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey3};
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      font-size: ${({ theme }) => theme.fontSize.x14};
    }
  }
`;
const InputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    flex-direction: column;
  }
`;
const ButtonBox = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;
const FirstWrapper = styled.div``;

export { Wrapper, Box, InputBox, ButtonBox, FirstWrapper };
