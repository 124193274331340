import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ComingSoonButton } from "../global/button";
import { ButtonBox } from "../cover-letter/create/manual/style";
import { EmptyFolder } from "../../assets";
import { Box } from "../cover-letter/create/style";
const Wrapper = styled.div`
  padding: 58px 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
  }
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 410px;
  flex-direction: column;
  margin-top: 100px;
  width: 100%;
  h1 {
    color: #f9fafb;
    text-align: center;
    font-family: "Montreal";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 40px;
  }
  p {
    color: #6b7280;
    font-family: "Montreal";
    font-size: 16px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 8px;
    @media (max-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;
const ResumeWriting = () => {
  return (
    <Wrapper>
      <Heading>
        <Box>
          <h1 className="heading">Resume</h1>
        </Box>
      </Heading>
      <EmptyContainer>
        <img src={EmptyFolder} alt="data" />
        <h1>You have no resume - yet</h1>
        <p>All your resumes will available here when you create them.</p>
        <ButtonBox>
          <ComingSoonButton disabled={false}>Coming soon</ComingSoonButton>
        </ButtonBox>
      </EmptyContainer>
    </Wrapper>
  );
};

export default ResumeWriting;
