import React, { useState } from "react";
import { ContactWrapper, ContactInfo, ContactForm } from "./style";
import { FormInput } from "../../global/formInput";
import { GoogleButton } from "../../global/button";
import { alerts } from "../../../utils/alert";
import { contactUs } from "../../../services/contactService";
import { useMutation } from "react-query";
const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const onSubmit = (e: any) => {
    e.preventDefault();
    if (fullName.length < 2) {
      alerts.error("Error", "Full Name must contain at least 2 letters.");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alerts.error("Error", "Invalid email address.");
      return;
    }

    if (message.length < 6) {
      alerts.error("Error", "Message must contain at least 6 characters.");
      return;
    }
    setLoading(true);
    Contact.mutate({
      email: email,
      message: message,
      name: fullName,
    });
  };
  const Contact = useMutation(contactUs, {
    onSuccess: (data) => {
      setEmail("");
      setMessage("");
      setFullName("");
      setLoading(false);
      alerts.success("Contact us Success", data.message);
    },
    onError: (error: any) => {
      setLoading(false);
      alerts.error("Contact us Failed", error);
    },
  });
  return (
    <ContactWrapper id="contact">
      <ContactInfo>
        <h1>Contact Us</h1>
        <p>
          Feel free to drop us a line using the form provided. We're here to
          assist you with any inquiries you may have.
        </p>
      </ContactInfo>
      <ContactForm>
        <FormInput
          label="Full name"
          type="text"
          value={fullName}
          stateHandler={setFullName}
          holder="e.g Peter Pan"
        />
        <FormInput
          label="Email address"
          type="email"
          value={email}
          stateHandler={setEmail}
          holder="name@example.com"
        />
        <FormInput
          label="Write your message"
          type="textarea"
          value={message}
          stateHandler={setMessage}
          holder="What do you want to say..."
        />
        <GoogleButton
          onClick={onSubmit}
          disabled={!message || !email || !fullName}
          loading={loading}
          className="button"
        >
          Send your message
        </GoogleButton>
      </ContactForm>
    </ContactWrapper>
  );
};

export default Contact;
