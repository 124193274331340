import { Routes, Route } from "react-router-dom";
import Login from "../../component/authentication/login";
import SignUp from "../../component/authentication/signup";
import ForgotPassword from "../../component/authentication/forgot-password";
import ResetPassword from "../../component/authentication/reset-password";
import VerifyEmail from "../../component/authentication/verify-email";

function AuthRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:id" element={<ResetPassword />} />
      <Route path="/verify-email/:id" element={<VerifyEmail />} />
    </Routes>
  );
}

export default AuthRoutes;
