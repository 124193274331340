import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Wrapper,
  ResumeBox,
  ImageBox,
  ImageContainer,
  PreviousResumeBox,
  UploadedResumeBox,
  Separator,
  ButtonBox,
} from "./style";
import useDragAndDrop from "../../../../hook/useDragandDrop";
import { Cancel, UploadImage, smallUploadImage } from "../../../../assets";
import { bytesToKilobytes } from "../../../../utils";
import ProgressBar from "../../../global/progress";
import { CustomButton, LandingEditButton } from "../../../global/button";
import { alerts } from "../../../../utils/alert";
import { useMutation } from "react-query";
import { getResumes, uploadResume } from "../../../../services/resumeService";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTH_SERVER_URL } from "../../../../config/config";
import axios from "axios";
import { SpinnerContainer } from "../..";
import Spinner from "../../../global/pageLoader";
import {
  ActivityLabels,
  trackButtonClick,
  trackPageView,
} from "../../../../utils/analytics";
import { ButtonBack } from "../../../../utils/util";
interface Resume {
  resumeId: string;
  resumeName: string;
  resumeSize: string | number;
}
const CreateCoverLetterResume = () => {
  const location = useLocation();
  const [latestResume, setLatestResume] = useState<Resume | null>(null);
  trackPageView("cover letter resume", location.pathname);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageloading, setPageLoading] = useState(false);
  const navigate = useNavigate();
  const [isSubmitCalled, setSubmitCalled] = useState(false);
  const onSubmit = () => {
    if (!file) {
      alerts.error("Error", "No file selected");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    if (isSubmitCalled) {
      return;
    } else {
      submit(formData);
      setSubmitCalled(true);
    }
  };

  const {
    dragging,
    file,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleClick,
    clearFile,
  } = useDragAndDrop(onSubmit);
  useEffect(() => {
    if (file) {
      setSubmitCalled(false);
    }
  }, [file]);

  const submit = (payload: FormData) => {
    setProgress(0);
    if (isSubmitCalled) {
      console.log("submit function has already been called.");
      return;
    }
    setSuccess(false);
    setError(false);
    const headers = {
      Authorization: `Bearer ${localStorage
        .getItem("token")!
        .replace(/"/g, "")}`,
    };
    return axios
      .post(`${AUTH_SERVER_URL}/resume`, payload, {
        headers,
        onUploadProgress: (progressEvent) => {
          const prevProgress =
            (progressEvent.loaded / progressEvent.total!) * 100;
          console.log(prevProgress, "prgress");
          setProgress(prevProgress);
        },
      })
      .then((response) => {
        const j = response.data;
        if (!j.success) {
          const errorMessage = j.message || "Unknown error";
          setLoading(false);
          alerts.error("Upload Failed", errorMessage);
          setError(true);
          throw new Error(errorMessage);
        } else {
          trackButtonClick(ActivityLabels.RESUME_UPLOADED);
          alerts.success("Upload Success", "Upload successful");
          setSuccess(true);
          setError(false);
          if (file) {
            let data = {
              resumeId: j.data.resumeId,
              resumeName: file.name,
              resumeSize: file.size,
            };
            setLatestResume(data);
          }
        }
        return j;
      })
      .catch((err) => {
        setLoading(false);
        if (err.code === "ERR_NETWORK") {
          alerts.error("Upload Failed", "File size is too large");
          setError(true);
          setSuccess(false);
          return;
        }
        alerts.error("Upload Failed", err.message);
        setError(true);
        setSuccess(false);
        throw err.message.replace(/^Error:\s*/, "");
      })
      .finally(() => {
        setSubmitCalled(true);
      });
  };

  const fetchResumes = async () => {
    setPageLoading(true);
    try {
      const response = await getResumes();
      const latestResume = response.data[0];
      setLatestResume(latestResume);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    } finally {
      setPageLoading(false);
    }
  };
  useEffect(() => {
    fetchResumes();
  }, []);

  return (
    <Wrapper>
      <Box>
        <ButtonBack />
        <h1 className="heading">Upload a resume</h1>
        <p className="sub-text">
          Upload a PDF document of your resume to generate a cover letter.
        </p>
      </Box>
      {pageloading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          {!file && latestResume && (
            <>
              <PreviousResumeBox>
                <h1>Reuse a previously uploaded resume</h1>
                <UploadedResumeBox>
                  <div className="first-content">
                    <div className="image">
                      <img src={smallUploadImage} alt="data" />
                    </div>
                    <div className="text">
                      <h2>{latestResume?.resumeName}</h2>
                      <span>{latestResume?.resumeSize} </span>
                    </div>
                  </div>
                  <div className="button">
                    <LandingEditButton
                      onClick={(e: any) => {
                        navigate(
                          `/dashboard/cover-letter/create/information/${latestResume?.resumeId}`
                        );
                      }}
                    >
                      Reuse
                    </LandingEditButton>
                  </div>
                </UploadedResumeBox>
              </PreviousResumeBox>
              <Separator>
                <span>OR</span>
              </Separator>
            </>
          )}
          <ResumeBox>
            <h1>Upload a new resume</h1>
            <ImageBox
              className={`drop-box ${dragging ? "dragging" : ""}`}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleClick}
            >
              <img src={UploadImage} alt="data" />
              {!file && (
                <>
                  <p>Upload a file or drag and drop</p>
                  <span>PDF up to 1MB</span>
                </>
              )}
              {file && !success && !error && (
                <>
                  <ImageContainer>
                    <div className="name">
                      <h2>{file.name}</h2>
                      <h2>Uploading... please wait</h2>
                      <span>{bytesToKilobytes(file.size)} KB </span>
                    </div>
                    <div
                      data-clear-button="true"
                      onMouseDown={clearFile}
                      className="image"
                    >
                      <img src={Cancel} alt="rep" />
                    </div>
                  </ImageContainer>
                  <ProgressBar data-clear-button="true" percent={progress} />
                </>
              )}
              {file && error && (
                <>
                  <p className="failed">Oops! Upload failed</p>
                  <h3>{file.name}</h3>
                  <span>{bytesToKilobytes(file.size)} KB </span>
                  <div className="buttons">
                    <LandingEditButton>Try again</LandingEditButton>
                  </div>
                </>
              )}
              {file && success && (
                <>
                  <p>{file.name}</p>
                  <span>{bytesToKilobytes(file.size)} KB </span>
                  <div className="buttons">
                    <LandingEditButton>Upload new</LandingEditButton>
                  </div>
                </>
              )}
            </ImageBox>
          </ResumeBox>
          {success && !error && (
            <ButtonBox>
              <CustomButton
                disabled={false}
                onClick={(e: any) => {
                  navigate(
                    `/dashboard/cover-letter/create/information/${latestResume?.resumeId}`
                  );
                }}
              >
                Generate cover letter
              </CustomButton>
            </ButtonBox>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default CreateCoverLetterResume;
