import styled from "styled-components";

const Wrapper = styled.div`
  padding: 58px 44px;
  margin-bottom: 200px;
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    padding: 5px 16px;
  }
  a {
    text-decoration: none;
    width: 49%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .soon {
    width: 49%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
const Box = styled.div`
  .heading {
    font-family: "Montreal";
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.x24};
    line-height: 30px;
    color: ${({ theme }) => theme.colors.grey6};
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      font-size: ${({ theme }) => theme.fontSize.x20};
    }
  }
  .sub-text {
    font-family: "Montreal";
    font-style: normal;
    font-weight: 400;
    padding-top: 8px;
    font-size: ${({ theme }) => theme.fontSize.x16};
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey3};
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      font-size: ${({ theme }) => theme.fontSize.x14};
    }
  }
`;
const Cards = styled.div`
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .carding {
    width: 49%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    flex-direction: column;
    gap: 30px;
  }
  .resume {
    background: ${({ theme }) => theme.colors.resumeColor};
  }
`;
const Card = styled.div`
  border-radius: 8px;
  border: 1px solid #2b2a2a;
  background: #232323;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 24px;
  height: 136px;
  div {
    padding-left: 24px;
    @media (max-width: 768px) {
      width: 60%;
    }
    h1 {
      font-feature-settings: "ss01" on;
      font-family: "Montreal";
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #f1f1f1;
    }
    p {
      color: #bbb;
      padding-top: 8px;
      font-feature-settings: "ss01" on;
      font-family: "Montreal";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
const ComingSoon = styled.div`
  display: flex;
  padding: 5px 10px;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #dcfce7;
  white-space: nowrap;
  p {
    font-family: inherit;
    color: #166534 !important;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
  }
`;
const QuickActions = styled.div`
  margin-top: 80px;
  h1 {
    color: #fff;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const QuickBoxes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
  }
  .coming {
    display: flex;
    align-items: center;
  }
`;
const QuickBox = styled.div`
  width: 100%;
  display: flex;
  height: 136px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #2b2a2a;
  background: #232323;
  @media (max-width: 768px) {
    width: 100%;
  }
  p {
    color: #f1f1f1;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  img {
    cursor: pointer;
  }
`;

export {
  Wrapper,
  Box,
  Card,
  Cards,
  ComingSoon,
  QuickActions,
  QuickBoxes,
  QuickBox,
};
