import { atom } from "recoil";

export const resume = atom({
  key: "resumeState",
  default: {
    fullName: "",
    role: "",
    email: "",
    telephone: "",
    bio: "",
    linkedin: "",
    websiteUrl: "",
    education: [
      {
        school: "",
        degree: "",
        course: "",
        location: "",
        startDate: "",
        endDate: "",
      },
    ],
    experience: [
      {
        company: "",
        role: "",
        workType: "",
        location: "",
        startDate: "",
        endDate: "",
        description: "",
      },
    ],
  },
});
