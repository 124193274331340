import { useState } from "react";
import { Wrapper, LogoBox, ContentBox, Form } from "../style";
import { Logo, HandShake } from "../../../assets";
import { GoogleButton } from "../../global/button";
import { FormInput } from "../../global/formInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { alerts } from "../../../utils/alert";
import { verifyEmail } from "../../../services/authService";
import { useLocalStorage } from "react-use";
import { useAuth } from "../../../providers/AuthProvider";

const VerifyEmail = () => {
  const [otp, setOtp] = useState("");
  const { id }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useLocalStorage<any>("token");
  const { setAuth } = useAuth();
  const [data, setData] = useState("");
  const onSubmit = () => {
    if (otp.length !== 6) {
      alerts.error("Error", "OTP must be  6 characters.");
      return;
    }
    setLoading(true);
    Verification.mutate({
      email: id,
      otp: otp,
    });
  };
  const Verification = useMutation(verifyEmail, {
    onSuccess: (data) => {
      setOtp("");
      setLoading(false);
      setData(data.accessToken);
      alerts.success("Verification Success", "Email successfully verified");
      setVerified(true);
    },
    onError: (error: any) => {
      setLoading(false);
      alerts.error("Verification Failed", error);
    },
  });
  const goToDashboard = () => {
    setToken(data);
    setAuth({ access_token: "true" });
    navigate("/dashboard");
  };
  return (
    <Wrapper>
      <LogoBox>
        <img src={Logo} alt="logo" />
      </LogoBox>
      {!verified && (
        <ContentBox>
          <img src={HandShake} alt="logo" />
          <div className="box">
            <h1 className="text">Verification Required</h1>
            <p className="sub-text">
              Enter the 6-digit code sent to <span>{id}</span> to verify your
              account
            </p>
          </div>
          <Form>
            <FormInput
              label="6-digit code"
              type="input"
              value={otp}
              stateHandler={setOtp}
            />
          </Form>
          <div className="buttons">
            <GoogleButton onClick={onSubmit} disabled={!otp} loading={loading}>
              Verify my account
            </GoogleButton>
          </div>
        </ContentBox>
      )}
      {verified && (
        <ContentBox>
          <img src={HandShake} alt="logo" />
          <div className="box">
            <h1 className="text">Account verified</h1>
            <p className="sub-text">
              Your account is now verified, you can now access your dashboard
            </p>
          </div>
          <div className="buttons">
            <GoogleButton onClick={goToDashboard}>
              Proceed to Login
            </GoogleButton>
          </div>
        </ContentBox>
      )}
    </Wrapper>
  );
};

export default VerifyEmail;
