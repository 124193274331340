import React from "react";
import Heading from "./header";
import Contact from "../contact";

const Pricing = () => {
  return (
    <>
      <Heading />
      {/* <Contact /> */}
    </>
  );
};

export default Pricing;
