import React from "react";
import styled from "styled-components";
const HeroVideoContainer = styled.div`
  border-radius: 22.5px;
  padding: 40px 150px;
  @media (max-width: 992px) {
    padding: 15px;
    margin-top: 130px;
  }
  iframe {
    border-radius: 22.5px;
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }
`;
const Demo = () => {
  return (
    <HeroVideoContainer>
      {/* <video autoPlay muted loop playsInline>
        <source
          src="https://www.loom.com/embed/0177b8dbf2d243999c6ee134f8117209?sid=70df06fc-2bd1-435b-aae8-af444af0a600"
          type="video/mp4"
        />
      </video> */}
      <iframe src="https://www.loom.com/embed/0177b8dbf2d243999c6ee134f8117209?sid=70df06fc-2bd1-435b-aae8-af444af0a600"></iframe>
    </HeroVideoContainer>
  );
};

export default Demo;
