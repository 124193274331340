import styled, { keyframes } from "styled-components";
const Wrapper = styled.div`
  padding: 58px 64px;
  .left {
    margin-left: 10px;
  }
  .width {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    padding: 5px 16px;
  }
  a {
    text-decoration: none;
    width: 48%;
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      width: 100%;
    }
  }
`;
const Box = styled.div`
  .heading {
    font-family: "Montreal";
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.x24};
    line-height: 30px;
    color: ${({ theme }) => theme.colors.grey6};
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      font-size: ${({ theme }) => theme.fontSize.x20};
    }
  }
  .sub-text {
    font-family: "Montreal";
    font-style: normal;
    font-weight: 400;
    padding-top: 8px;
    font-size: ${({ theme }) => theme.fontSize.x16};
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey3};
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      font-size: ${({ theme }) => theme.fontSize.x14};
    }
  }
`;
const InputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    flex-direction: column;
  }
`;
const ButtonBox = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;
const FirstWrapper = styled.div``;
const ButtonSecondBox = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    flex-direction: column;
  }
`;
const AddField = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #f9fafb;
    padding-left: 10px;
  }
`;
const ArrayFields = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .box {
    display: flex;
    align-items: center;
    margin-top: 40px;
    img {
      cursor: pointer;
    }
    .delete {
      margin-left: 13px;
    }
    .info {
      display: flex;
      flex-direction: column;
      margin-left: 24px;

      .info-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #f9fafb;
        margin-bottom: 8px;
      }
      .info-location {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #f9fafb;
      }
      .info-date {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #6b7280;
        padding-left: 12px;
      }
    }
  }
`;
export {
  Wrapper,
  Box,
  InputBox,
  ButtonBox,
  FirstWrapper,
  ButtonSecondBox,
  AddField,
  ArrayFields,
};
