import styled from "styled-components";

const PricingTabs = styled.div`
  width: 284px;
  height: 66px;
  border-radius: 200px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(252, 149, 112, 0.08);
`;
const Tab = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 24px 20px;
  border-radius: 200px;
  height: 40px;
  background-color: ${(props) => (props.$active ? "#FFDAB5" : "transparent")};
  p {
    color: ${(props) => (props.$active ? "#000000" : "#936453 ")};
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const Box = styled.div`
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    width: 100%;
  }
  .heading {
    font-family: "Montreal";
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.x24};
    line-height: 30px;
    color: ${({ theme }) => theme.colors.grey6};
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      font-size: ${({ theme }) => theme.fontSize.x20};
    }
  }
  .sub-text {
    font-family: "Montreal";
    font-style: normal;
    font-weight: 400;
    padding-top: 8px;
    font-size: ${({ theme }) => theme.fontSize.x16};
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey3};
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      font-size: ${({ theme }) => theme.fontSize.x14};
    }
  }
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 150px;
  gap: 24px;
  margin-bottom: 100px;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 50px;
  }
`;
const Card = styled.div<{ $pro: boolean }>`
  width: 100%;
  height: 570px;
  padding: 24px;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: ${(props) => (props.$pro ? "#FFDAB5" : "#1C1C1C")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border: ${(props) => (props.$pro ? "none" : "1px solid #2b2a2a ")};
  div {
    width: 100%;
  }
  .button {
    width: 100%;
    height: 60px;
    background-color: ${(props) => (props.$pro ? "#0D0D0D" : "#F55B23")};
  }
`;

const HeadingBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
const HeadingText = styled.div<{ $pro: boolean }>`
  color: ${(props) => (props.$pro ? "#18181B" : "#d1d5db")};
  font-feature-settings: "ss01" on;
  font-family: "Montreal";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const Amount = styled.div<{ $pro: boolean }>`
  color: ${(props) => (props.$pro ? "#18181B" : "#fff")};
  margin-bottom: 32px;
  font-family: "Montreal";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.4px;
  span {
    color: ${(props) => (props.$pro ? " #672107" : "#d1d5db")};
    font-size: 18px;
    font-weight: 400;
  }
`;
const FeatureBox = styled.div<{ $pro: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 16px;
  p {
    color: ${(props) => (props.$pro ? "#09090b" : "#D1D5DB")};
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 8px;
  }
`;
export {
  PricingTabs,
  Tab,
  Box,
  CardContainer,
  Card,
  FeatureBox,
  Amount,
  HeadingText,
  HeadingBox,
};
