import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Pagination, PaginationProps } from "antd";
import { useMedia } from "react-use";
import { leftImage, rightImage, EmptyFolder } from "../../assets";
import { Box } from "./create/style";
import { CustomButton, LandingEditButton } from "../global/button";
import { Link } from "react-router-dom";
import { ButtonBox } from "./create/manual/style";
import { getCoverLetter } from "../../services/coverLetter";
import { format, parseISO } from "date-fns";
import Spinner from "../global/pageLoader";
import { useInfiniteQuery } from "react-query";
import { useAuth } from "../../providers/AuthProvider";
export const SpinnerContainer = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  color: white;
  padding: 58px 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 28px 16px;
  }
  .button-to {
    @media (max-width: 768px) {
      align-self: flex-end;
      margin-bottom: 20px;
    }
  }
  .view {
    height: 40px !important;
  }
  a {
    text-decoration: none;
  }
  .ant-pagination-next {
    margin-left: 24px;
    /* float: right; */
  }
  .ant-pagination .ant-pagination-item a {
    color: #4f4b5c !important;
    padding: 3px 16px;
  }
  .ant-pagination
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #f9fafb !important;
  }
  .ant-pagination .ant-pagination-item-active a {
    color: #ffffff !important;
    background-color: #f55b23 !important;
    border-radius: 8px !important;
  }
  .ant-pagination .ant-pagination-item-active {
    font-weight: 600;
    background-color: #ffffff;
    border-color: #1677ff;
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
  }
  .ant-pagination-prev {
    margin-right: 24px;
    /* float: left; */
  }
  .ant-pagination-options {
    display: none !important;
  }
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  max-width: 410px;
  flex-direction: column;
  margin-top: 100px;
  width: 100%;
  h1 {
    color: #f9fafb;
    text-align: center;
    font-family: "Montreal";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 40px;
  }
  p {
    color: #6b7280;
    font-family: "Montreal";
    font-size: 16px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 8px;
    padding-bottom: 24px;
    @media (max-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;
const CoverPagination = styled.div`
  text-align: center;
  margin-top: 40px;
  border-top: 1.5px solid #2b2a2a;
  width: 100%;
  padding-top: 20px;
  ul {
    width: 100%;
  }
  .arrow {
    display: flex;
    align-items: center;
    p {
      color: #f9fafb;
      font-family: "Montreal";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .prev {
      padding-left: 8px;
    }
    .next {
      padding-right: 8px;
    }
  }
`;
const CoverTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 68px;
  width: 100%;

  h1 {
    color: #f9fafb;
    font-family: "Montreal";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .tag {
    margin-left: 16px;
    border-radius: 66.667px;
    background: #f4f0fc;
    display: flex;
    height: 24px;
    padding: 10px;
    align-items: center;
    gap: 8px;
    p {
      color: #9667e0;
      font-family: "Montreal";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.28px;
    }
  }
`;
const CoverTable = styled.div`
  border: 1px solid #2b2a2a;
  border-radius: 10px 10px 0px 0px;
  width: 100%;

  .table_header {
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #2b2a2a;
    background: #232323;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    .table_heading {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p {
        color: #f9fafb;
        font-family: "Montreal";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .index {
      flex-basis: 10%;
      @media (max-width: 768px) {
        flex-basis: 15%;
      }
    }
    .view {
      flex-basis: 10%;
      @media (max-width: 768px) {
        flex-basis: 20%;
      }
    }
    .role {
      flex-basis: 30%;
      @media (max-width: 768px) {
        display: none;
        flex-basis: 0%;
      }
    }
    .name {
      flex-basis: 30%;
      @media (max-width: 768px) {
        flex-basis: 45%;
      }
    }
    .date {
      flex-basis: 20%;
      @media (max-width: 768px) {
        display: none;
        flex-basis: 0%;
      }
    }
  }
  .table_desc {
    border-bottom: 1px solid #2b2a2a;
    background: #1c1c1c;
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    .table_heading {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p {
        color: #6b7280;
        font-family: "Montreal";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .index {
      flex-basis: 10%;
      @media (max-width: 768px) {
        flex-basis: 15%;
      }
    }
    .view {
      flex-basis: 10%;
      border: none;
      padding: 0;
      background-color: transparent;
      /* text-decoration: underline; */
      @media (max-width: 768px) {
        flex-basis: 20%;
      }
    }
    .role {
      flex-basis: 30%;
      @media (max-width: 768px) {
        display: none;
        flex-basis: 0%;
      }
    }
    .name {
      flex-basis: 30%;
      @media (max-width: 768px) {
        flex-basis: 45%;
      }
    }
    .date {
      flex-basis: 20%;
      @media (max-width: 768px) {
        display: none;
        flex-basis: 0%;
      }
    }
  }
`;
interface CoverLetter {
  coverId: string;
  coverLetter: string;
  createdAt: string;
  employer: string;
  jobTitle: string;
  updatedAt: string;
  userId: string;
}
const CoverWriting = () => {
  const [coverLetter, setCoverLetter] = useState<CoverLetter[]>([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const isSmallScreen = useMedia("(max-width: 576px)", true);
  const { userDetails, userToken } = useAuth();
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    console.log(current, pageSize);
  };
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (!isSmallScreen) {
      if (type === "prev") {
        return (
          <div className="arrow">
            <img src={leftImage} alt="arrow" />
          </div>
        );
      }
      if (type === "next") {
        return (
          <div className="arrow">
            <img src={rightImage} alt="arrow" />
          </div>
        );
      }
    }
    return originalElement;
  };
  useEffect(() => {
    const cachedCoverLetter = localStorage.getItem("cachedCoverLetter");
    if (cachedCoverLetter !== undefined && cachedCoverLetter !== null) {
      console.log(cachedCoverLetter, "cachedCoverLetter2");
      setCoverLetter(JSON.parse(cachedCoverLetter));
      setShow(true);
      setLoading(false);
      fetchCoverLetterInBackground();
    } else {
      fetchCoverLetter();
    }
  }, []);
  const fetchCoverLetterInBackground = async () => {
    try {
      const response = await getCoverLetter();
      setCoverLetter(response.data || []);
      localStorage.setItem(
        "cachedCoverLetter",
        JSON.stringify(response.data || [])
      );
    } catch (error) {
      console.error("Error fetching cover letter:", error);
    }
  };
  const fetchCoverLetter = async () => {
    setLoading(true);
    try {
      const response = await getCoverLetter();
      setCoverLetter(response.data || []);
      setLoading(false);
      setShow(true);
      localStorage.setItem(
        "cachedCoverLetter",
        JSON.stringify(response.data || [])
      );
    } catch (error) {
      setLoading(false);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page
  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };
  const paginatedData = coverLetter
    ? coverLetter.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : [];
  return (
    <Wrapper>
      <Heading>
        <Box>
          <h1 className="heading">Cover Letter</h1>
          <p className="sub-text">
            Fill the form below as clearly as possible to generate a suitable
            cover letter
          </p>
        </Box>
        {(loading || (coverLetter.length > 0 && coverLetter.length > 0)) && (
          <Link className="button-to" to={"/dashboard/cover-letter/create"}>
            <ButtonBox>
              <CustomButton disabled={false} loading={loading}>
                Generate cover letter
              </CustomButton>
            </ButtonBox>
          </Link>
        )}
      </Heading>
      {loading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          {show && coverLetter && coverLetter.length > 0 && (
            <>
              <CoverTitle>
                <h1>Overview</h1>
                <div className="tag">
                  <p>{coverLetter.length}</p>
                </div>
              </CoverTitle>
              <CoverTable>
                <div className="table_header">
                  <div className="table_heading index">
                    <p>S/N</p>
                  </div>
                  <div className="table_heading role">
                    <p>Role applied</p>
                  </div>
                  <div className="table_heading name">
                    <p>Company name</p>
                  </div>
                  <div className="table_heading date">
                    <p>Date generated</p>
                  </div>
                  <div className="table_heading view"></div>
                </div>
                {coverLetter &&
                  paginatedData.map((item: CoverLetter, index: number) => (
                    <div className="table_desc" key={index}>
                      <div className="table_heading index">
                        <p>{index + 1}</p>
                      </div>
                      <div className="table_heading role">
                        <p>{item.jobTitle}</p>
                      </div>
                      <div className="table_heading name">
                        <p>{item.employer}</p>
                      </div>
                      <div className="table_heading date">
                        <p>{format(parseISO(item.createdAt), "d MMM, yyyy")}</p>
                      </div>
                      <div className="table_heading view">
                        <Link
                          to={`/dashboard/cover-letter/detail/${item.coverId}`}
                        >
                          <LandingEditButton className="view">
                            View
                          </LandingEditButton>
                        </Link>
                      </div>
                    </div>
                  ))}
              </CoverTable>
              <CoverPagination>
                <Pagination
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={2}
                  total={coverLetter.length}
                  itemRender={itemRender}
                  style={{ display: "inline-block" }}
                  current={currentPage}
                  pageSize={pageSize}
                  onChange={onPageChange}
                />
              </CoverPagination>
            </>
          )}
          {show && coverLetter && coverLetter.length === 0 && (
            <EmptyContainer>
              <img src={EmptyFolder} alt="data" />
              <h1>You have no cover letter - yet</h1>
              <p>
                All your generated cover letters will available here when you
                create them.
              </p>
              <Link to={"/dashboard/cover-letter/create"}>
                <ButtonBox>
                  <CustomButton disabled={false}>
                    Generate cover letter
                  </CustomButton>
                </ButtonBox>
              </Link>
            </EmptyContainer>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default CoverWriting;
