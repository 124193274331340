import styled from "styled-components";
const Wrapper = styled.div`
  padding: 58px 44px;
  margin-bottom: 100px;
  .left {
    margin-left: 10px;
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      width: 100%;
      margin-left: 0px;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    padding: 5px 16px;
  }
  a {
    text-decoration: none;
  }
`;
const Box = styled.div`
  margin-bottom: 70px;
  .heading {
    font-family: "Montreal";
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.x24};
    line-height: 30px;
    color: ${({ theme }) => theme.colors.grey6};
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      font-size: ${({ theme }) => theme.fontSize.x20};
    }
  }
  .sub-text {
    font-family: "Montreal";
    font-style: normal;
    font-weight: 400;
    padding-top: 8px;
    font-size: ${({ theme }) => theme.fontSize.x16};
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey3};
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      font-size: ${({ theme }) => theme.fontSize.x14};
    }
  }
`;
const ResumeBox = styled.div`
  h1 {
    color: #fff;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 12px;
  }
`;
const ImageBox = styled.div`
  display: flex;
  margin-top: 12px;
  width: 100%;
  padding: 40px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #52525b;
  background: #232323;
  border-image: repeating-linear-gradient(
    45deg,
    #52525b,
    #52525b 10px,
    transparent 10px,
    transparent 20px
  );
  border-image-slice: 1;
  border-radius: 8px;
  cursor: pointer;
  p {
    color: #fff;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 24px;
  }
  h3 {
    color: #fff;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  span {
    color: #6b7280;
    padding-top: 5px;
    text-align: center;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .failed {
    color: #f87171;
    text-align: center;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 8px;
  }
  .buttons {
    padding-top: 24px;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  h2 {
    color: #f9fafb;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      font-size: 14px;
    }
  }
  span {
    color: #6b7280;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 4px;
  }
  img {
    cursor: pointer;
  }
`;
const PreviousResumeBox = styled.div`
  h1 {
    color: #fff;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 12px;
  }
`;
const UploadedResumeBox = styled.div`
  display: flex;
  margin-top: 12px;
  width: 100%;
  height: 124px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #2b2a2a;
  background: #232323;
  h2 {
    color: #f9fafb;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  span {
    color: #6b7280;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 4px;
  }
  .first-content {
    display: flex;
    align-items: center;
    .text {
      padding-left: 16px;
    }
  }
`;
const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
  span {
    color: #6b7280;
    text-align: center;
    font-feature-settings: "ss01" on;
    font-family: "Montreal";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`;
const ButtonBox = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;
export {
  Separator,
  Wrapper,
  Box,
  ResumeBox,
  ImageBox,
  ImageContainer,
  PreviousResumeBox,
  UploadedResumeBox,
  ButtonBox,
};
