import { ProductButton } from "../../../global/button";
import {
  Wrapper,
  HeaderText,
  SubText,
  Box,
  ImageBox,
  ProductHuntBox,
  BoxFlex,
} from "./style";
import { HeroImg } from "../../../../assets";
import { Link } from "react-router-dom";
import ReactTyped from "react-typed";
const Hero = () => {
  return (
    <Wrapper>
      <Box>
        <HeaderText className="lg">
          Polish your applications, get
          <br /> noticed by{" "}
          <ReactTyped
            loop
            typeSpeed={200}
            backSpeed={150}
            strings={["employers", "recruiters", "hiring manager"]}
            smartBackspace
            shuffle={false}
            backDelay={3}
            fadeOut={false}
            fadeOutDelay={100}
            loopCount={0}
            showCursor
            cursorChar="|"
          />
        </HeaderText>
        <HeaderText className="mb">
          Polish your applications, get noticed by{" "}
          <ReactTyped
            loop
            typeSpeed={200}
            backSpeed={150}
            strings={["employers", "recruiters", "hiring managers"]}
            smartBackspace
            shuffle={false}
            backDelay={3}
            fadeOut={false}
            fadeOutDelay={100}
            loopCount={0}
            showCursor
            cursorChar="|"
          />
        </HeaderText>
        <SubText>
          Helping you put your best foot forward with a<br /> professionally
          curated application, increasing your
          <br /> chances of being hired.
        </SubText>
        <BoxFlex>
          <ProductHuntBox>
            <Link
              to="https://www.producthunt.com/posts/myresumai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-myresumai"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=412826&theme=neutral"
                alt="myresumAI - Helping&#0032;you&#0032;get&#0032;hired&#0044;&#0032;effortlessly | Product Hunt"
                width="250"
                height="52"
              />
            </Link>
          </ProductHuntBox>{" "}
          <Link to="/auth/signup">
            <ProductButton>Sign up - It’s free</ProductButton>
          </Link>
        </BoxFlex>
      </Box>
      <ImageBox>
        <div className="highlight-image"></div>
        <img src={HeroImg} alt="bg-i0mage" className="image" />
      </ImageBox>
    </Wrapper>
  );
};
export default Hero;
