import React from "react";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { themes } from "./theme/theme";
import { GlobalStyle } from "./style";
import { Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import Landing from "./pages/routes/landing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AuthRoutes from "./pages/routes/auth";
import DashboardRoutes from "./pages/routes/dashboardRoute";

function App() {
  // window.localStorage.setItem(
  //   "token",
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJjNDc0MjhlZi1kMzYzLTQ3YTYtYjQ2Mi01ZjI1MTlkZGMyNDQiLCJpYXQiOjE2OTU0NDY4NDUsImV4cCI6MTY5NTg3ODg0NX0.-L43Uwa-JEvlTftkz6PnWimr3ZSm5r23vz6DTs2tEwY"
  // );
  return (
    <RecoilRoot>
      <ThemeProvider theme={themes}>
        <GlobalStyle />
        <Routes>
          <Route path="/auth/*" element={<AuthRoutes />} />
          <Route path="/dashboard/*" element={<DashboardRoutes />} />
          <Route path="/*" element={<Landing />} />
        </Routes>
        <ToastContainer
          position="top-right"
          className={"app__toast"}
          bodyClassName={"app__toast__body"}
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick={false}
          rtl={false}
          draggable
          pauseOnFocusLoss
          theme="light"
        />
      </ThemeProvider>
    </RecoilRoot>
  );
}
export default App;
