import React from "react";
import { Route, Routes } from "react-router-dom";
import Information from "../component/resume/personal-information";
import Education from "../component/resume/education";
import ResumeWriting from "../component/resume";
import Experience from "../component/resume/experience";
const Resume = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ResumeWriting />} />
        <Route path="/create/personal-information" element={<Information />} />
        <Route path="/create/education" element={<Education />} />
        <Route path="/create/experience" element={<Experience />} />
      </Routes>
    </>
  );
};
export default Resume;
