import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMedia } from "react-use";
import {
  PricingTabs,
  Tab,
  Box,
  CardContainer,
  Card,
  FeatureBox,
  Amount,
  HeadingText,
  HeadingBox,
} from "./style";
import { GoogleButton } from "../../global/button";
import { BlackCheck, BlueCheck, Hot } from "../../../assets";
import {
  FREE_PLAN_MONTLY,
  FREE_PLAN_YEARLY,
  MONTHLY_BASIC_VARIANT_ID,
  MONTHLY_PRO_VARIANT_ID,
  PRO_PLAN_MONTLY,
  PRO_PLAN_YEARLY,
  STORE_ID,
  YEARLY_BASIC_VARIANT_ID,
  YEARLY_PRO_VARIANT_ID,
} from "../../../config/config";
import { useAuth } from "../../../providers/AuthProvider";
import { useMutation } from "react-query";
import { createPaymentConfirmation } from "../../../services/coverLetter";
import { alerts } from "../../../utils/alert";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  ActivityLabels,
  trackButtonClick,
  trackPageView,
} from "../../../utils/analytics";
import { isDateInFutureOrToday } from "../../../utils";
import { ButtonBack } from "../../../utils/util";
export const SpinnerContainer = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  color: white;
  padding: 58px 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 28px 16px;
  }
  .button-to {
    @media (max-width: 768px) {
      align-self: flex-end;
      margin-bottom: 20px;
    }
  }
  .view {
    height: 40px !important;
  }
  a {
    text-decoration: none;
  }
  .ant-pagination-next {
    margin-left: 24px;
    /* float: right; */
  }
  .ant-pagination .ant-pagination-item a {
    color: #4f4b5c !important;
    padding: 3px 16px;
  }
  .ant-pagination
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #f9fafb !important;
  }
  .ant-pagination .ant-pagination-item-active a {
    color: #ffffff !important;
    background-color: #f55b23 !important;
    border-radius: 8px !important;
  }
  .ant-pagination .ant-pagination-item-active {
    font-weight: 600;
    background-color: #ffffff;
    border-color: #1677ff;
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
  }
  .ant-pagination-prev {
    margin-right: 24px;
    /* float: left; */
  }
  .ant-pagination-options {
    display: none !important;
  }
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 50px;
  }
`;
interface SubscriptionPlan {
  name: string;
  amount: string[];
  features: string[];
  buttonText: string;
  link: string;
  variantId: string[];
  disabled: boolean;
}

const Pricing = () => {
  const [loading, setLoading] = useState(false);
  const isSmallScreen = useMedia("(max-width: 576px)", true);
  const lemonApiKey = process.env.REACT_APP_LEMON_SQUEEZY_PROD_API_KEY;
  const [tabNumber, setTabNumber] = useState(0);
  const { userDetails } = useAuth();
  const location = useLocation();
  trackPageView("pricing-page", location.pathname);
  type SubscriptionsArray = SubscriptionPlan[];
  const Plans: SubscriptionsArray = [
    {
      name: "Basic",
      amount: [FREE_PLAN_MONTLY, FREE_PLAN_YEARLY],
      features: [
        "Up to 3 responses a day",
        "Smart personalization",
        "Basic reporting and analytics",
      ],
      buttonText: isDateInFutureOrToday(userDetails?.user?.premiumDueDate)
        ? "Cancel Subscription"
        : "Current Plan",
      link: "/free",
      variantId: [MONTHLY_BASIC_VARIANT_ID, YEARLY_BASIC_VARIANT_ID],
      // variantId: ["121926", "121953"],
      disabled: !isDateInFutureOrToday(userDetails?.user?.premiumDueDate)
        ? true
        : false,
    },
    {
      name: "Pro",
      amount: [PRO_PLAN_MONTLY, PRO_PLAN_YEARLY],
      features: [
        "Basic reporting and analytics",
        "Keyword optimization",
        "Smart personalization",
        "Advanced reporting and analytics",
        "Download responses as PDF",
      ],
      buttonText: isDateInFutureOrToday(userDetails?.user?.premiumDueDate)
        ? "Current Plan"
        : "Subscribe to Pro",
      link: "/pro",
      // variantId: ["121940", "121954"],
      variantId: [MONTHLY_PRO_VARIANT_ID, YEARLY_PRO_VARIANT_ID],
      disabled: isDateInFutureOrToday(userDetails?.user?.premiumDueDate)
        ? true
        : false,
    },
  ];
  const [response, setResponse] = useState<string>("");
  const handleButtonClick = async (type: SubscriptionPlan, index: number) => {
    if (!type.disabled) {
      try {
        setLoadingStates((prevLoadingStates) => {
          const newLoadingStates = [...prevLoadingStates];
          newLoadingStates[index] = true; // Set loading state for the clicked button to true
          return newLoadingStates;
        });
        const response = await fetch(
          "https://api.lemonsqueezy.com/v1/checkouts",
          {
            method: "POST",
            headers: {
              Accept: "application/vnd.api+json",
              "Content-Type": "application/vnd.api+json",
              Authorization: `Bearer ${lemonApiKey}`,
            },
            body: JSON.stringify({
              data: {
                type: "checkouts",
                attributes: {
                  product_options: {
                    redirect_url: `https://myresumai.xyz/dashboard/profile/pricing?id=payment-success&product=${
                      tabNumber === 0 ? "monthly" : "yearly"
                    }`,
                    receipt_button_text: "Go to your account",
                    receipt_link_url: `https://myresumai.xyz/pricing?id='producthunt'&product=${
                      tabNumber === 0 ? "monthly" : "yearly"
                    }`,
                    receipt_thank_you_note:
                      "Thank you for subscribinb! Click on the button to access your account.",
                  },
                  checkout_data: {
                    email: userDetails.user.email,
                    name: userDetails.user.userName,
                    custom: {
                      user_id: userDetails.user.userId,
                    },
                  },
                },
                relationships: {
                  store: {
                    data: {
                      type: "stores",
                      id: STORE_ID,
                    },
                  },
                  variant: {
                    data: {
                      type: "variants",
                      id: type.variantId[tabNumber],
                    },
                  },
                },
              },
            }),
          }
        );

        const data = await response.json();
        setLoadingStates((prevLoadingStates) => {
          const newLoadingStates = [...prevLoadingStates];
          newLoadingStates[index] = false; // Reset loading state for the clicked button to false
          return newLoadingStates;
        });
        setResponse(JSON.stringify(data, null, 2));
        window.open(data.data.attributes.url, "_blank");
      } catch (error) {
        setLoadingStates((prevLoadingStates) => {
          const newLoadingStates = [...prevLoadingStates];
          newLoadingStates[index] = false; // Reset loading state for the clicked button to false
          return newLoadingStates;
        });
      }
    }
  };
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("id")) {
      const idValue = searchParams.get("id");
      if (searchParams.get("product")) {
        const productValue = searchParams.get("product");
        console.log("id", idValue, productValue);
        if (
          idValue === "payment-success" &&
          (productValue === "monthly" || productValue === "yearly")
        ) {
          console.log("id", idValue, productValue);

          let payload = {
            subscriptionType: productValue,
          };
          ConfirmPayment.mutate(payload);
        }
      }
    }
  }, []);
  const ConfirmPayment = useMutation(createPaymentConfirmation, {
    onSuccess: (data) => {
      trackButtonClick(ActivityLabels.PAYMENT_SUCCESS);
      alerts.success("Subscription Success", "Subscritption successfull");
      setLoadingStates((prevLoadingStates) => {
        const newLoadingStates = [...prevLoadingStates];
        newLoadingStates[1] = false; // Reset loading state for the clicked button to false
        return newLoadingStates;
      });
    },
    onError: (error: any) => {
      setLoadingStates((prevLoadingStates) => {
        const newLoadingStates = [...prevLoadingStates];
        newLoadingStates[1] = false; // Reset loading state for the clicked button to false
        return newLoadingStates;
      });
      alerts.error("Failed", error);
    },
  });
  const [loadingStates, setLoadingStates] = useState(Plans.map(() => false));

  return (
    <Wrapper>
      <Heading>
        <Box>
          <ButtonBack />
          <h1 className="heading">Pricing plans</h1>
          <p className="sub-text">
            Pick a plan that’s right for you, your business or your community
          </p>
        </Box>
        <PricingTabs className="tabs">
          <Tab $active={tabNumber === 0} onClick={() => setTabNumber(0)}>
            <p>Monthly</p>
          </Tab>
          <Tab $active={tabNumber === 1} onClick={() => setTabNumber(1)}>
            <p>Yearly (Save 20%)</p>
          </Tab>
        </PricingTabs>
      </Heading>
      <CardContainer>
        {Plans.map((item, index) => {
          return (
            <Card $pro={item.name === "Pro"} key={index}>
              <div>
                <HeadingBox>
                  <HeadingText $pro={item.name === "Pro"}>
                    {item.name}
                  </HeadingText>
                  {item.name === "Pro" && <img src={Hot} alt="hot" />}
                </HeadingBox>
                <Amount $pro={item.name === "Pro"}>
                  ${item.amount[tabNumber]}
                  <span>/{tabNumber === 0 ? "month" : "year"}</span>
                </Amount>
                {item.features.map((key) => {
                  return (
                    <FeatureBox $pro={item.name === "Pro"} key={key}>
                      <img
                        src={item.name === "Pro" ? BlackCheck : BlueCheck}
                        alt="hot"
                      />
                      <p>{key}</p>
                    </FeatureBox>
                  );
                })}
              </div>
              <div>
                <GoogleButton
                  className="button"
                  onClick={() => handleButtonClick(item, index)}
                  disabled={item.disabled}
                >
                  {item.buttonText}
                  {loadingStates[index] && (
                    <i className="fa fa-spinner fa-spin"></i>
                  )}
                </GoogleButton>
              </div>
            </Card>
          );
        })}
      </CardContainer>
    </Wrapper>
  );
};

export default Pricing;
