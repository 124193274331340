import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { EMMA_TRACKING_ID, GOOGLE_AUTH_ID, TRACKING_ID } from "./config/config";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./providers/AuthProvider";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
ReactGA.initialize([
  {
    trackingId: TRACKING_ID,
  },
  {
    trackingId: EMMA_TRACKING_ID,
  },
]);
const queryClient = new QueryClient();
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_AUTH_ID}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
reportWebVitals();
