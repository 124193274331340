import styled from "styled-components";
import { Link } from "react-router-dom";
const Wrapper = styled.div`
  height: 80px;
  background: #1c1c1c;
  padding: 20px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(252, 149, 112, 0.08);
  a {
    text-decoration: none;
  }
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    padding: 20px 24px;
  }
  .logo-image {
    width: 90%;
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      width: 30%;
    }
  }
`;
const NavLinks = styled.ul`
  display: flex;
  align-items: center;
  .active {
    color: #fc9570 !important;
  }
`;
const Links = styled(Link)`
  margin-right: 24px;
  color: #f9fafb;
  :hover {
    color: #fc9570;
  }

  p {
    color: inherit;
    text-align: center;
    font-family: "Montreal";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export { Wrapper, NavLinks, Links };
