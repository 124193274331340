import React, { useEffect, useState } from "react";
import { Wrapper, Box, InputBox, ButtonBox, FirstWrapper } from "./style";
import { CustomButton } from "../../global/button";
import { FormInput } from "../../global/formInput";
import { alerts } from "../../../utils/alert";
import TagsInput from "../../global/formInput/keypadInput";
import { useMutation } from "react-query";
import {
  createPersonalInformation,
  getPersonalInformation,
} from "../../../services/coverLetter";
import { useLocation, useNavigate } from "react-router-dom";
import { trackPageView } from "../../../utils/analytics";
import { ButtonBack } from "../../../utils/util";
const PersonalInformation = () => {
  const location = useLocation();
  const [firstName, setFirstName] = useState("");
  const [loading, setLoading] = useState(false);
  trackPageView("personal-information", location.pathname);
  const [years, setYears] = useState("");
  const [notable, setNotable] = useState("");
  const [personalInformation, setPersonalInformation] = useState("");
  const [relevant, setRelevant] = useState<string[]>([]);
  const [initialTags, setInitialTags] = useState<string[]>([]);
  const onSubmit = () => {
    if (!firstName || !years || relevant.length < 1 || !notable) {
      alerts.error("Error", "Please fill all the fields correctly");
      return;
    }
    let payload = {
      // name: firstName,
      // YOE: years,
      // achievement: notable,
      skills: relevant,
    };
    setLoading(true);
    CreatePersonal.mutate(payload);
  };
  const navigate = useNavigate();

  const CreatePersonal = useMutation(createPersonalInformation, {
    onSuccess: (data) => {
      alerts.success(
        "Personal Information Success",
        "Personal Information added successfully"
      );
      setLoading(false);
      window.localStorage.setItem("filled", "true");
      setTimeout(function () {
        navigate(`/dashboard`);
      }, 1000); // Wait 1 second
    },
    onError: (error: any) => {
      setLoading(false);
      alerts.error("Failed", error);
    },
  });
  const handleChange = (tags: string[]) => {
    setRelevant(tags);
  };
  useEffect(() => {
    fetchPersonalInformation();
  }, []);
  const fetchPersonalInformation = async () => {
    try {
      const response = await getPersonalInformation();
      setPersonalInformation(response.userInfo);
      setYears(response.userInfo.YOE);
      setNotable(response.userInfo.achievement);
      setFirstName(response.userInfo.name);
      setInitialTags(response.userInfo.skills.split(", "));
      setRelevant(response.userInfo.skills.split(", "));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Wrapper>
      <Box>
        <ButtonBack />
        <h1 className="heading">Personal Information</h1>
        <p className="sub-text">
          Fill the form below as clearly as possible to generate better
          responses
        </p>
      </Box>
      <FirstWrapper>
        <InputBox>
          <FormInput
            label="First name"
            index="1"
            type="text"
            value={firstName}
            className="left"
            stateHandler={setFirstName}
            holder="e.g Peter"
          />
          <FormInput
            label="Years of experience"
            index="2"
            type="text"
            value={years}
            stateHandler={setYears}
            className="right"
            holder="e.g 3 years"
          />
        </InputBox>
        <FormInput
          label="Notable achievements"
          index="3"
          type="textarea"
          value={notable}
          stateHandler={setNotable}
          holder="Write something interesting..."
        />
        <FormInput
          label="Relevant skills (To be separated by Enter Key or comma)"
          index="4"
          type="keypad"
          onTagsChange={handleChange}
          holder="Write your skills..."
          initialTags={initialTags}
        />
        <ButtonBox>
          <CustomButton
            disabled={!firstName || !years || relevant.length < 1 || !notable}
            onClick={onSubmit}
            loading={loading}
          >
            Update Profile
          </CustomButton>
        </ButtonBox>
      </FirstWrapper>
    </Wrapper>
  );
};

export default PersonalInformation;
