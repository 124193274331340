import styled from "styled-components";

const Wrapper = styled.div`
  height: auto;
  height: 100%;
  padding-bottom: 80px;
  @media (max-width: 768px) {
    margin-top: 60px;
  }
  .contact {
    margin-top: 70px;
  }
  a {
    text-decoration: none;
  }
  span {
    color: #ffb45c;
  }
`;
const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 110px;
  position: relative;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  .mb {
    display: none;
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      display: block;
    }
  }
  .lg {
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      display: none;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    padding: 0px 8px;
    padding-top: 70px;
    margin-bottom: 0px;
  }
`;
const HeaderText = styled.h1`
  font-weight: 500;
  display: inline;
  font-size: 60px;
  line-height: 80px;
  font-family: "Montreal";
  text-align: center;
  color: #ffffff;
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    font-size: 32px;
    line-height: 45px;
    text-transform: capitalize;
  }
`;
const SubText = styled.p`
  font-weight: 400;
  font-size: 22px;
  line-height: normal;
  text-align: center;
  color: #d1d5db;
  padding: 32px 0px;
  width: 58%;
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    font-size: 16px;
    line-height: 20px;
    padding: 16px 0px;
    width: 100%;
  }
`;
const ImageBox = styled.div`
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 500px;
  @media (max-width: 768px) {
    height: auto;
  }
  .highlight-image {
    position: absolute;
    z-index: -10;
    width: 700px;
    height: 350px;
    border-radius: 600px;
    background: rgba(245, 91, 35, 0.53);
    filter: blur(200px);
    top: 100px;
    margin-top: 30px;
    opacity: 0.8;
    @media (max-width: 768px) {
      width: 100%;
      height: 150px;
    }
  }
  .pricing-image {
    position: absolute;
    z-index: -10;
    width: 600px;
    height: 600px;
    border-radius: 600px;
    background: rgba(245, 91, 35, 0.53);
    filter: blur(200px);
    @media (max-width: 768px) {
      width: auto;
    }
  }
  .image {
    position: absolute;
    top: 30px;
    width: 85%;
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      top: 50px;
      width: 100%;
    }
  }
  .tabs {
    position: absolute;
    top: -110px;
    @media (max-width: ${({ theme }) => theme.responsive.mb}) {
      top: 50px;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    margin-top: 0px;
  }
`;
const ProductHuntBox = styled.div`
  margin-top: 2px;
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    margin-top: 20px;
  }
`;
const BoxFlex = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.responsive.mb}) {
    flex-direction: column-reverse;
  }
`;
export { Wrapper, HeaderText, SubText, Box, ImageBox, BoxFlex, ProductHuntBox };
