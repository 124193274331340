import React, { useEffect, useState } from "react";
import { FormInput } from "../../global/formInput";
import {
  Wrapper,
  Box,
  InputBox,
  ButtonSecondBox,
  FirstWrapper,
  AddField,
  ArrayFields,
} from "./style";
import { CustomButton, EditButton } from "../../global/button";
import { Plus, Delete, Scholar, EditForm } from "../../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { resume } from "../../../state/resume";
import { trackPageView } from "../../../utils/analytics";
const Experience = () => {
  const location = useLocation();
  trackPageView("resume-experience", location.pathname);
  const options = [
    { value: "Full Time", label: "Full Time" },
    { value: "Part Time", label: "Part Time" },
    { value: "Contract", label: "Contract" },
  ];
  trackPageView("resume-experience", location.pathname);
  const [loading, setLoading] = useState(false);
  const [editingIndex, setEditingIndex] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setEditingIndex(0);
  }, []);
  const [inputs, setInputs] = useState([
    {
      company: "",
      role: "",
      workType: "",
      location: "",
      startDate: "",
      endDate: "",
      description: "",
    },
  ]);
  useEffect(() => {
    setIsFormValid(
      inputs.every(
        (input) =>
          input.company &&
          input.role &&
          input.startDate &&
          input.endDate &&
          input.location &&
          input.description
      )
    );
  }, [inputs]);
  const handleChange = (index: any, event: any, type: string) => {
    const values: any = [...inputs];
    values[index][type] = event;
    setInputs(values);
  };

  const handleAdd = () => {
    if (isFormValid) {
      const values = [...inputs];
      values.push({
        company: "",
        role: "",
        workType: "",
        location: "",
        startDate: "",
        endDate: "",
        description: "",
      });
      setInputs(values);
      setEditingIndex(values.length - 1);
    }
  };

  const handleRemove = (index: any) => {
    const values = [...inputs];
    values.splice(index, 1);
    setInputs(values);
  };
  const setObject = useSetRecoilState(resume);
  const object = useRecoilValue(resume);
  const handleSubmit = () => {
    setLoading(true);
    setObject({
      ...object,
      experience: inputs,
    });
    setLoading(false);
    console.log(object);
  };
  return (
    <Wrapper>
      <Box>
        <h1 className="heading">Work experience</h1>
        <p className="sub-text">
          Fill the form below as clearly as possible to generate a suitable
          resume
        </p>
      </Box>
      {inputs.map((input, index) => (
        <div>
          <div
            key={index}
            style={{ display: editingIndex === index ? "none" : "block" }}
          >
            <ArrayFields>
              <div className="box">
                <img src={Scholar} alt="scholar" />
                <div className="info">
                  <p className="info-text">{input.company}</p>
                  <p className="info-location">
                    {input.role}
                    <span className="info-date">
                      {input.startDate} - {input.endDate} {input.location}
                    </span>
                  </p>
                </div>
              </div>
              <div className="box">
                <img src={EditForm} alt="edit" />
                <img
                  className="delete"
                  onClick={() => handleRemove(index)}
                  src={Delete}
                  alt="delete"
                />
              </div>
            </ArrayFields>
          </div>
        </div>
      ))}

      <FirstWrapper>
        {inputs.map((input, index) => (
          <div
            key={index}
            style={{ display: editingIndex === index ? "block" : "none" }}
          >
            <InputBox>
              <FormInput
                label="Company/Employer"
                index="1"
                type="text"
                value={input.company}
                className="left"
                stateHandler={(event) => handleChange(index, event, "company")}
                holder="e.g Spotify"
              />
              <FormInput
                label="Job title"
                index="2"
                type="text"
                value={input.role}
                className="right"
                stateHandler={(event) => handleChange(index, event, "role")}
                holder="e.g Product Designer"
              />
            </InputBox>
            <InputBox>
              <FormInput
                label="Work type"
                index="3"
                type="select"
                selectOptions={options}
                value={input.workType}
                stateHandler={(event) => handleChange(index, event, "workType")}
                holder="e.g Full time"
                className="left width"
              />
              <FormInput
                label="Location"
                index="4"
                type="text"
                value={input.location}
                stateHandler={(event) => handleChange(index, event, "location")}
                className="right"
                holder="e.g London"
              />
            </InputBox>
            <InputBox>
              <FormInput
                label="Start date"
                index="5"
                type="date"
                value={input.startDate}
                stateHandler={(event) =>
                  handleChange(index, event, "startDate")
                }
                holder="MM/YYYY"
                className="left"
              />
              <FormInput
                label="End date"
                index="6"
                type="date"
                value={input.endDate}
                stateHandler={(event) => handleChange(index, event, "endDate")}
                className="right"
                holder="MM/YYYY"
              />
            </InputBox>
            <FormInput
              label="Role description"
              index="7"
              type="textarea"
              value={input.description}
              stateHandler={(event) =>
                handleChange(index, event, "description")
              }
              holder="Write something interesting..."
            />
          </div>
        ))}
        <AddField onClick={handleAdd}>
          <img src={Plus} alt="plus" />
          <p className="text">Add Experience</p>
        </AddField>

        <ButtonSecondBox>
          <EditButton onClick={() => navigate(-1)}>Go back</EditButton>
          <div className="left">
            <CustomButton onClick={handleSubmit}>Continue</CustomButton>
          </div>
        </ButtonSecondBox>
      </FirstWrapper>
    </Wrapper>
  );
};

export default Experience;
