import React, { useEffect, useState } from "react";
import {
  SecondWrapper,
  GeneratedBox,
  BottomBox,
  Pagination,
  Wrapper,
  ButtonSecondBox,
  Box,
} from "../create/manual/style";
import { CustomButton, EditButton } from "../../global/button";
import Spinner from "../../global/spinner";
import ReactTyped from "react-typed";
import { alerts } from "../../../utils/alert";
import { LeftArrow, RightArrow, Retry, Copy, Edit } from "../../../assets";
import CopyToClipboard from "react-copy-to-clipboard";
import { getCoverLetterDetail } from "../../../services/coverLetter";
import { useParams } from "react-router-dom";
import { ReplaceVariables, fixesIssuesWithCoverLetter } from "../../../utils";
import { ButtonBack } from "../../../utils/util";
const CoverDetails = () => {
  const [coverLetter, setCoverLetter] = useState<any>({});
  const [copySuccess, setCopySuccess] = useState("Copy to clipboard");
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState<any>([]);
  const [counter, setCounter] = useState(1);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const incrementCounter = (upperLimit: number) => {
    if (counter < upperLimit) {
      setCounter(counter + 1);
    }
  };
  const decrementCounter = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
  };
  useEffect(() => {
    if (id) {
      fetchCoverLetter(id);
    }
  }, [id]);
  const fetchCoverLetter = async (coverId: string) => {
    setLoading(true);
    try {
      const response = await getCoverLetterDetail(coverId);
      setCoverLetter(response.data);
      let result = [];
      result.push(fixesIssuesWithCoverLetter(response.data.coverLetter));
      const updatedArray = [...result];
      setResult(updatedArray);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Wrapper>
      <Box>
        <ButtonBack />
        <h1 className="heading">Cover Letter</h1>
        <p className="sub-text">This is your generated cover letter</p>
      </Box>
      <SecondWrapper>
        <p className="text">Generated Cover Letter</p>
        <GeneratedBox>
          {loading && <Spinner text="fetching" />}
          {!loading &&
            result.length > 0 &&
            ReplaceVariables({}, result[counter - 1])}
          <BottomBox>
            <Pagination>
              <img onClick={decrementCounter} src={LeftArrow} alt="retry" />
              <p className="text">
                {counter}/{result.length}
              </p>
              <img
                onClick={() => incrementCounter(result.length)}
                src={RightArrow}
                alt="retry"
              />
            </Pagination>
          </BottomBox>
        </GeneratedBox>

        <ButtonSecondBox>
          <CopyToClipboard
            text={result[counter - 1]}
            onCopy={() => {
              alerts.success("Copied", "Cover letter Copied");
            }}
          >
            <CustomButton>
              <img src={Copy} alt="retry" />
              {copySuccess}
            </CustomButton>
          </CopyToClipboard>
        </ButtonSecondBox>
      </SecondWrapper>
    </Wrapper>
  );
};

export default CoverDetails;
