import React, { useState } from "react";
import {
  Wrapper,
  HeaderText,
  SubText,
  Box,
  ImageBox,
} from "../../home/hero/style";
import {
  PricingTabs,
  Tab,
  CardContainer,
  Card,
  Amount,
  HeadingText,
  HeadingBox,
  FeatureBox,
} from "./style";
import { BlueCheck, BlackCheck, Hot } from "../../../../assets";
import { GoogleButton } from "../../../global/button";
import {
  FREE_PLAN_MONTLY,
  FREE_PLAN_YEARLY,
  PRO_PLAN_MONTLY,
  PRO_PLAN_YEARLY,
} from "../../../../config/config";
interface SubscriptionPlan {
  name: string;
  amount: string[];
  features: string[];
  buttonText: string;
  link: string;
}
const Heading = () => {
  const [tabNumber, setTabNumber] = useState(0);
  type SubscriptionsArray = SubscriptionPlan[];
  const Plans: SubscriptionsArray = [
    {
      name: "Basic",
      amount: [FREE_PLAN_MONTLY, FREE_PLAN_YEARLY],
      features: [
        "Up to 3 responses a day",
        "Smart personalization",
        "Basic reporting and analytics",
      ],
      buttonText: "Get started - It’s free",
      link: "/free",
    },
    {
      name: "Pro",
      amount: [PRO_PLAN_MONTLY, PRO_PLAN_YEARLY],
      features: [
        "Basic reporting and analytics",
        "Keyword optimization",
        "Smart personalization",
        "Advanced reporting and analytics",
        "Download responses as PDF",
      ],
      buttonText: "Subscribe to Pro",
      link: "/pro",
    },
  ];
  return (
    <Wrapper>
      <Box>
        <HeaderText>
          Simple and transparent
          <br /> <span>pricing</span>
        </HeaderText>
        <SubText>
          Pick a plan that’s right for you, your business or your
          <br /> community.
        </SubText>
      </Box>
      <ImageBox>
        <div className="pricing-image"></div>
        <PricingTabs className="tabs">
          <Tab $active={tabNumber === 0} onClick={() => setTabNumber(0)}>
            <p>Monthly</p>
          </Tab>
          <Tab $active={tabNumber === 1} onClick={() => setTabNumber(1)}>
            <p>Yearly (Save 20%)</p>
          </Tab>
        </PricingTabs>
        <CardContainer>
          {Plans.map((item) => {
            return (
              <Card $pro={item.name === "Pro"}>
                <div>
                  <HeadingBox>
                    <HeadingText $pro={item.name === "Pro"}>
                      {item.name}
                    </HeadingText>
                    {item.name === "Pro" && <img src={Hot} alt="hot" />}
                  </HeadingBox>
                  <Amount $pro={item.name === "Pro"}>
                    ${item.amount[tabNumber]}
                    <span>/{tabNumber === 0 ? "month" : "year"}</span>
                  </Amount>
                  {item.features.map((key) => {
                    return (
                      <FeatureBox $pro={item.name === "Pro"} key={key}>
                        <img
                          src={item.name === "Pro" ? BlackCheck : BlueCheck}
                          alt="hot"
                        />
                        <p>{key}</p>
                      </FeatureBox>
                    );
                  })}
                </div>
                <div>
                  <GoogleButton className="button">
                    {item.buttonText}
                  </GoogleButton>
                </div>
              </Card>
            );
          })}
        </CardContainer>
      </ImageBox>
    </Wrapper>
  );
};

export default Heading;
