import React, { useEffect, useState } from "react";
import { Wrapper, LogoBox, ContentBox, SubText, Form } from "../style";
import { Google, Logo, HandShake } from "../../../assets";
import { GoogleButton, LoginButton } from "../../global/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { setLocalUserInstance } from "../../../state/localstorage";
import { alerts } from "../../../utils/alert";
import { FormInput } from "../../global/formInput";
import { loginGoogleUser, loginUser } from "../../../services/authService";
import { useMutation } from "react-query";
import { useLocalStorage } from "react-use";
import { useAuth } from "../../../providers/AuthProvider";
import {
  ActivityLabels,
  trackButtonClick,
  trackPageView,
} from "../../../utils/analytics";

const Login = () => {
  const location = useLocation();
  trackPageView("login", location.pathname);
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const [user, setUser] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [googleloading, setGoogleLoading] = useState(false);
  const [token, setToken] = useLocalStorage<any>("token");
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  const Login = useMutation(loginUser, {
    onSuccess: (data) => {
      setEmail("");
      setPassword("");
      setLoading(false);
      setGoogleLoading(false);
      setToken(data.data.token);
      setAuth({ access_token: "true" });
      trackButtonClick(ActivityLabels.LOGIN);
      alerts.success("Login Success", "Login successful");
      // window.location.replace("/dashboard");
    },
    onError: (error: any) => {
      setLoading(false);
      setGoogleLoading(false);
      if (
        error ===
        "Email not verified. Verification email has been sent to your email"
      ) {
        navigate(`/auth/verify-email/${email}`);
      }
      alerts.error("Login Failed", error);
    },
  });
  useEffect(() => {
    if (user.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res: any) => {
          console.log(user, res, "checkhere");
          setGoogleLoading(true);
          setLocalUserInstance(res.data);
          onLogin(user.access_token);
        })
        .catch((err: any) => console.log(err));
    }
  }, [user]);
  const onLogin = (token: string) => {
    GoogleLogin.mutate({
      accessToken: token,
    });
  };
  const GoogleLogin = useMutation(loginGoogleUser, {
    onSuccess: (data) => {
      setEmail("");
      setPassword("");
      setLoading(false);
      setGoogleLoading(false);
      setToken(data.data.token);
      setAuth({ access_token: "true" });
      trackButtonClick(ActivityLabels.LOGIN);
      alerts.success("Login Success", "Login successful");
      // window.location.replace("/dashboard");
    },
    onError: (error: any) => {
      setLoading(false);
      setGoogleLoading(false);
      if (
        error ===
        "Email not verified. Verification email has been sent to your email"
      ) {
        navigate(`/auth/verify-email/${email}`);
      }
      alerts.error("Login Failed", error);
    },
  });
  const onSubmit = () => {
    if (!password || !email) {
      alerts.error("Error", "Please fill all the fields correctly");
      return;
    }
    setLoading(true);
    Login.mutate({ email: email, password: password });
  };
  return (
    <Wrapper>
      <LogoBox>
        <img src={Logo} alt="logo" />
      </LogoBox>
      <ContentBox>
        <img src={HandShake} alt="logo" />
        <div className="box">
          <h1 className="text">Welcome back</h1>
          <p className="sub-text">Log in to your account</p>
        </div>
        <Form>
          <FormInput
            label="Email address"
            type="email"
            value={email}
            stateHandler={setEmail}
            holder="name@example.com"
          />
          <FormInput
            label="Password"
            type="password"
            value={password}
            stateHandler={setPassword}
            holder="*********"
          />
        </Form>
        <SubText>
          <Link className="link" to="/auth/forgot-password">
            Forgot password?
          </Link>
        </SubText>
        <div className="buttons">
          <GoogleButton
            onClick={onSubmit}
            disabled={!password || !email}
            loading={loading}
          >
            Log in
          </GoogleButton>
          <SubText>
            <span className="span-new">or</span>
          </SubText>
          <LoginButton onClick={() => login()}>
            <img src={Google} alt="retry" />
            Log in with Google{" "}
            {googleloading && <i className="fa fa-spinner fa-spin"></i>}
          </LoginButton>
          <SubText>
            Don’t have an account?{" "}
            <Link to="/auth/signup">
              <span className="span"> Sign up</span>
            </Link>
          </SubText>
        </div>
      </ContentBox>
    </Wrapper>
  );
};

export default Login;
