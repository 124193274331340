import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { Logo } from "../../../../assets";
import { Link } from "react-router-dom";
import Burger from "./burger";
import styled from "styled-components";
const Wrapper = styled.div`
  h1 {
    font-size: 40px;
    text-align: center;
  }

  .nav-container {
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    background: #1c1c1c;
    z-index: 100;
  }
  .burger {
    z-index: 1000;
  }
  .logo-image-nav {
    width: 70%;
  }

  .bars {
    width: 30px;
    height: 4px;
    color: white;
  }

  .bars::before,
  .bars::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    background: #000;
  }

  .bars::before {
    margin-top: 9px;
  }

  .bars::after {
    margin-top: 18px;
  }

  nav {
    position: absolute;
    width: 100%;
    height: 10px;
    background: #1c1c1c;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 1000;
  }

  nav ul {
    list-style: none;
  }

  nav ul li {
    margin: 50px 0;
  }

  nav ul li a {
    color: #fafafa;
    font-size: 2em;
    text-decoration: none;
    font-weight: 500;
    position: relative;
    opacity: 0;
    pointer-events: none;
  }

  nav ul li a:after {
    content: "";
    width: 100%;
    position: absolute;
    height: 3px;
    border-radius: 5px;
    background: #fff;
    bottom: -10px;
    left: 0;
    transform-origin: left;
    transition: transform 0.5s ease;
    transform: scaleX(0);
  }

  nav ul li a:hover:after {
    transform: scaleX(1);
  }

  nav .close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 6%;
    right: 5%;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
  }

  nav h2 {
    position: absolute;
    top: 3%;
    left: 5%;
    opacity: 0;
    pointer-events: none;
    color: #fff;
    font-size: 2em;
  }

  nav .close div::before,
  nav .close div::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    background: #fff;
    transition: background 0.5s ease;
  }
  nav .close div::before {
    transform: rotate(-45deg);
  }

  nav .close div::after {
    transform: rotate(45deg);
  }

  nav .close:hover div::before,
  nav .close:hover div::after {
    background: rgb(209, 3, 3);
  }

  @media (max-width: 992px) {
    h1 {
      font-size: 25px;
    }
  }
`;
const Navbar: React.FC = () => {
  const tl = useRef(
    gsap.timeline({ defaults: { duration: 1, ease: "expo.inOut" } })
  );

  const openNav = () => {
    tl.current.play();
    tl.current
      .to(".nav", { right: 0 })
      .to(".nav", { height: "100vh" }, "-=.1")
      .to(
        ".nav ul li a",
        { opacity: 1, pointerEvents: "all", stagger: 0.2 },
        "-=.8"
      )
      .to(".close", { opacity: 1, pointerEvents: "all" }, "-=.8")
      .to(".nav h2", { opacity: 1 }, "-=1");
  };

  const closeNav = () => {
    tl.current.reverse();
  };

  useEffect(() => {
    tl.current.reverse();
  }, []);

  return (
    <Wrapper>
      <div className="nav-container">
        <Link to="/">
          <img className="logo-image-nav" src={Logo} alt="logo" />
        </Link>
        <div className="burger" onClick={openNav}>
          <Burger />
        </div>
      </div>
      <nav className="nav">
        <h2>
          <img className="logo-image-nav" src={Logo} alt="logo" />
        </h2>
        <div className="close" onClick={closeNav}>
          <div></div>
        </div>
        <ul>
          <li>
            <Link onClick={closeNav} to="/demo">
              See a Demo
            </Link>
          </li>
          <li>
            <Link
              target="_blank"
              to="https://substack.com/profile/169715094-myresumai?r=9ite1&utm_medium=ios&utm_source=profile"
            >
              Blog
            </Link>
          </li>
          <li>
            <Link onClick={closeNav} to="/contact">
              Contact Us
            </Link>
          </li>
          <li>
            <Link onClick={closeNav} to="/pricing">
              Pricing
            </Link>
          </li>
          <li>
            <Link onClick={closeNav} to="/auth/login">
              Log In
            </Link>
          </li>
        </ul>
      </nav>
    </Wrapper>
  );
};

export default Navbar;
