import React from "react";
import { Wrapper, NavLinks, Links } from "./style";
import { Logo } from "../../../../assets";
import { LandingEditButton } from "../../../global/button";
import { Link, useLocation } from "react-router-dom";
const Header = () => {
  const location = useLocation();
  const NavItems = [
    { name: "See a Demo", to: "/demo", button: false },
    { name: "Pricing", to: "/pricing", button: false },
    {
      name: "Blog",
      to: "https://substack.com/profile/169715094-myresumai?r=9ite1&utm_medium=ios&utm_source=profile",
      button: false,
    },
    { name: "Contact Us", to: "/contact", button: false },
    { name: "Log In", to: "/auth/login", button: true },
  ];
  return (
    <Wrapper>
      <Link to="/">
        <img src={Logo} alt="logo" className="logo-image" />
      </Link>
      <NavLinks>
        {NavItems.map((item) => {
          return (
            <>
              {item.name === "Blog" && (
                <Links
                  key={item.to}
                  to={item.to}
                  target="_blank"
                  className={location.pathname === item.to ? "active" : ""}
                >
                  {!item.button && <p>{item.name}</p>}
                  {item.button && (
                    <LandingEditButton>{item.name}</LandingEditButton>
                  )}
                </Links>
              )}
              {item.name !== "Blog" && (
                <Links
                  key={item.to}
                  to={item.to}
                  className={location.pathname === item.to ? "active" : ""}
                >
                  {!item.button && <p>{item.name}</p>}
                  {item.button && (
                    <LandingEditButton>{item.name}</LandingEditButton>
                  )}
                </Links>
              )}
            </>
          );
        })}
      </NavLinks>
    </Wrapper>
  );
};

export default Header;
