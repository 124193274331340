import React from "react";
import { Route, Routes } from "react-router-dom";
import ProfilePage from "../component/profile";
import Information from "./personal-information";
import Pricing from "../component/profile/pricing";
const Profile = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ProfilePage />} />
        <Route path="/personal-information" element={<Information />} />
        <Route path="/pricing" element={<Pricing />} />
      </Routes>
    </>
  );
};
export default Profile;
