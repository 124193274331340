import React from "react";
import { Wrapper, Line, Content, LogoBox } from "./style";
import { Logo } from "../../../../assets";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Wrapper>
      <Line />
      <Content>
        <LogoBox>
          <img src={Logo} alt="logo" className="logo-image" />
          <p>{currentYear} MyresumAI. All rights reserved.</p>
        </LogoBox>
        {/* <List>
          <Link to="/demo">
            <li>
              <p>See a Demo</p>
            </li>
          </Link>
          <Link to="/pricing">
            <li>
              <p>Pricing</p>
            </li>
          </Link>
          <Link
            target="_blank"
            to="https://substack.com/profile/169715094-myresumai?r=9ite1&utm_medium=ios&utm_source=profile"
          >
            <li>
              <p>Blog</p>
            </li>
          </Link>
          <Link to="/contact">
            <li>
              <p>Contact Us</p>
            </li>
          </Link>
        </List> */}
      </Content>
    </Wrapper>
  );
};

export default Footer;
