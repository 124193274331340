import React, { useEffect, useState } from "react";
import {
  Wrapper,
  Box,
  Cards,
  Card,
  ComingSoon,
  QuickBox,
  QuickBoxes,
  QuickActions,
} from "./style";
import { Mail, MagicWand, Letter, ArrowLeft } from "../../assets";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { trackPageView } from "../../utils/analytics";
const Dashboard = () => {
  const location = useLocation();
  const { userDetails } = useAuth();
  trackPageView("dashboard", location.pathname);
  return (
    <Wrapper>
      <Box>
        <h1 className="heading">
          Welcome to MyresumAI, {userDetails.user.firstName}
          👋
        </h1>
        <p className="sub-text">What do you want to do today?</p>
      </Box>
      <Cards>
        <div className="carding">
          <Link to="/dashboard/cover-letter">
            <Card>
              <img src={Letter} alt="data" />
              <div className="content">
                <h1>{userDetails.dashboardInfo.coversCount}</h1>
                <p>Cover letters generated</p>
              </div>
            </Card>
          </Link>
        </div>
        <div className="carding">
          <Link to="/dashboard/resume">
            <Card>
              <img src={Mail} alt="data" />
              <div className="content">
                <h1>0</h1>
                <p>Resume created</p>
              </div>
            </Card>
          </Link>
        </div>
      </Cards>
      <QuickActions>
        <h1>Quick actions</h1>
        <QuickBoxes>
          <Link to="/dashboard/cover-letter/create">
            <QuickBox>
              <p>Generate a Cover Letter</p>
              <img src={ArrowLeft} alt="data" />
            </QuickBox>
          </Link>
          <div className="soon">
            <QuickBox>
              <div className="coming">
                <p>Create a Resume</p>
                <ComingSoon>
                  <p>Coming Soon</p>
                </ComingSoon>
              </div>
              <img src={ArrowLeft} alt="data" />
            </QuickBox>
          </div>
        </QuickBoxes>
      </QuickActions>
    </Wrapper>
  );
};

export default Dashboard;
