export const GOOGLE_SHEET_URL =
  "https://script.google.com/macros/s/AKfycbw4KRntomCxYb6s2yV5QiwNJhqlneS7LIExNlAeFqdSTta1myBz6vljjOswROF-TQnk/exec";
export const AUTH_SERVER_URL = "https://backend.myresumai.com/api";
export const STORE_ID = "42214";
export const FREE_PLAN_MONTLY = "0";
export const FREE_PLAN_YEARLY = "0";
export const PRO_PLAN_MONTLY = "4.99";
export const PRO_PLAN_YEARLY = "59.99";
export const MONTHLY_BASIC_VARIANT_ID = "124587";
export const YEARLY_BASIC_VARIANT_ID = "124590";
export const MONTHLY_PRO_VARIANT_ID = "124591";
export const YEARLY_PRO_VARIANT_ID = "124592";
export const TRACKING_ID = "G-3TTHCGQQVD";
export const GOOGLE_AUTH_ID =
  "55179129249-b868v3c9hji8jejiqi10n5ude5t0nsur.apps.googleusercontent.com";
export const EMMA_TRACKING_ID = "G-B]3YZ5P78Z";
