import React from "react";
import PersonalInformation from "../component/profile/personal-information";
const Information = () => {
  return (
    <>
      <PersonalInformation />
    </>
  );
};
export default Information;
