import styled from "styled-components";
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding: 40px 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  background: #1c1c1c;
  @media (max-width: 768px) {
    padding: 40px 16px;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #374151;
`;
const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const LogoBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
  }
  img {
    @media (max-width: 992px) {
      width: 40%;
    }
  }
  p {
    color: #6b7280;
    font-family: "Montreal";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-top: 24px;
  }
`;
const List = styled.ul`
  display: flex;
  align-items: center;
  gap: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;
  }
  a {
    text-decoration: none;
    color: #f9fafb;
    :hover {
      color: #fc9570;
    }
  }
  li {
    list-style: none;
    p {
      color: inherit;
      text-align: center;
      font-family: "Montreal";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;
export { Wrapper, Line, Content, LogoBox, List };
