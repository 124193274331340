import React, { useState } from "react";
import { FormInput } from "../../global/formInput";
import { Wrapper, Box, ButtonBox, FirstWrapper } from "./style";
import { CustomButton } from "../../global/button";
import { getCurrentUserInstance } from "../../../state/localstorage";
import moment from "moment";
import { GOOGLE_SHEET_URL } from "../../../config/config";
import { alerts } from "../../../utils/alert";
import { useMutation } from "react-query";
import { feedbackUser } from "../../../services/contactService";
import {
  ActivityLabels,
  trackButtonClick,
  trackPageView,
} from "../../../utils/analytics";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/AuthProvider";

const CreateFeedback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails } = useAuth();
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  trackPageView("feedback", location.pathname);
  const leaveFeedback = () => {
    if (feedback.length < 6) {
      alerts.error("Error", "Feedback must contain at least 6 characters.");
      return;
    }
    setLoading(true);
    feedbackMessage.mutate({
      feedback: feedback,
    });
  };
  const feedbackMessage = useMutation(feedbackUser, {
    onSuccess: (data) => {
      setFeedback("");
      setLoading(false);
      trackButtonClick(ActivityLabels.FEEDBACK_GIVEN);
      alerts.success("Feedback Success", data.message);
      setTimeout(function () {
        navigate(`/dashboard`);
      }, 1000); // Wait 1 second
    },
    onError: (error: any) => {
      setLoading(false);
      alerts.error("Feedback Failed", error);
    },
  });
  return (
    <Wrapper>
      <Box>
        <h1 className="heading">Leave your Feedback</h1>
        <p className="sub-text">
          Would you be willing to share any particular feedback with us? Send us
          a message, we'd love to hear from you
        </p>
      </Box>
      {!submitted && (
        <FirstWrapper>
          <FormInput
            label="What feedback do you have?"
            type="textarea"
            className="noLeft"
            value={feedback}
            stateHandler={setFeedback}
            holder="Write something interesting..."
          />
          <ButtonBox>
            <CustomButton
              disabled={!feedback}
              onClick={leaveFeedback}
              loading={loading}
            >
              Leave a message
            </CustomButton>
          </ButtonBox>
        </FirstWrapper>
      )}
    </Wrapper>
  );
};

export default CreateFeedback;
