import React, { useState } from "react";
import { Wrapper, LogoBox, ContentBox, Form } from "../style";
import { Logo, HandShake } from "../../../assets";
import { GoogleButton } from "../../global/button";
import { FormInput } from "../../global/formInput";
import { Link, useParams } from "react-router-dom";
import { resetPassword, verifyOTP } from "../../../services/authService";
import { useMutation } from "react-query";
import { alerts } from "../../../utils/alert";
const ResetPassword = () => {
  const [otp, setOtp] = useState("");
  const { id }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [password, setPassword] = useState("");
  const onSubmit = () => {
    if (otp.length !== 6) {
      alerts.error("Error", "OTP must be  6 characters.");
      return;
    }
    setLoading(true);
    Verification.mutate({
      email: id,
      otp: otp,
    });
  };
  const Verification = useMutation(verifyOTP, {
    onSuccess: (data) => {
      setLoading(false);
      alerts.success("Verification Success", "Email successfully verified");
      setOtpVerified(true);
    },
    onError: (error: any) => {
      setLoading(false);
      alerts.error("Verification Failed", error);
    },
  });
  const onSubmitPassword = () => {
    if (password.length < 6) {
      alerts.error("Error", "Password must contain at least 6 characters.");
      return;
    }
    setLoading(true);
    VerificationPassword.mutate({
      email: id,
      password: password,
      otp: otp,
    });
  };
  const VerificationPassword = useMutation(resetPassword, {
    onSuccess: (data) => {
      setLoading(false);
      alerts.success("Verification Success", data.message);
      setVerified(true);
    },
    onError: (error: any) => {
      setLoading(false);
      alerts.error("Verification Failed", error);
    },
  });
  return (
    <Wrapper>
      <LogoBox>
        <img src={Logo} alt="logo" />
      </LogoBox>
      {!otpVerified && !verified && (
        <ContentBox>
          <img src={HandShake} alt="logo" />
          <div className="box">
            <h1 className="text">Password reset link sent</h1>
            <p className="sub-text">
              We’ve sent a link to <span>{id}</span> to allow you reset your
              password
            </p>
          </div>
          <Form>
            <FormInput
              label="6-digit code"
              type="input"
              value={otp}
              stateHandler={setOtp}
            />
          </Form>
          <div className="buttons">
            <GoogleButton onClick={onSubmit} disabled={!otp} loading={loading}>
              Reset Password
            </GoogleButton>
          </div>
        </ContentBox>
      )}
      {otpVerified && !verified && (
        <ContentBox>
          <img src={HandShake} alt="logo" />
          <div className="box">
            <h1 className="text">Reset Password</h1>
            <p className="sub-text">
              Create a new password that is secure and easy to remember
            </p>
          </div>
          <Form>
            <FormInput
              label="Enter a strong password"
              type="password"
              value={password}
              stateHandler={setPassword}
              holder="*********"
            />
          </Form>
          <div className="buttons">
            <GoogleButton
              onClick={onSubmitPassword}
              disabled={!password}
              loading={loading}
            >
              Create new password
            </GoogleButton>
          </div>
        </ContentBox>
      )}
      {otpVerified && verified && (
        <ContentBox>
          <img src={HandShake} alt="logo" />
          <div className="box">
            <h1 className="text">New password created</h1>
            <p className="sub-text">
              You’ve created a new password and your account is now secure
            </p>
          </div>
          <div className="buttons">
            <Link to="/auth/login">
              <GoogleButton>Log in to dashboard</GoogleButton>
            </Link>
          </div>
        </ContentBox>
      )}
    </Wrapper>
  );
};

export default ResetPassword;
