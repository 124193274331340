import React from "react";
import Loader from "../global/loader";
import Header from "./home/heading";
import Hero from "./home/hero";
import { trackPageView } from "../../utils/analytics";
import { useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();
  trackPageView("homepage", location.pathname);
  return (
    <>
      <Hero />
    </>
  );
};

export default Home;
