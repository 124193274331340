import styled from "styled-components";
const ContactWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 64px;
  padding-bottom: 80px;
  margin-top: 75px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 16px;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 35px;
  }
`;
const ContactInfo = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  h1 {
    color: #fafafa;
    font-feature-settings: "ss18" on, "fina" on;
    font-family: "Montreal";
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 35px;
    text-align: left;
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
  p {
    color: #d4d4d8;
    padding-top: 24px;
    width: 90%;
    font-family: "Montreal";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    @media (max-width: 768px) {
      font-size: 18px;
      width: fit-content;
    }
  }
`;
const ContactForm = styled.form`
  width: 50%;
  padding-left: 20px;
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
  }
  .button {
    margin-top: 24px;
  }
`;
export { ContactWrapper, ContactInfo, ContactForm };
