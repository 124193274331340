import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../component/dashboard";
import CoverLetter from "./coverLetter";
import Layout from "../component/layout";
import Resume from "./resume";
import Feedback from "./feedback";
import AuthGuard from "../component/authGuard";
import InnerContent from "../component/protectedRoute/innerContent";
import ProtectedRoutes from "../component/protectedRoute";
import Profile from './profile'
const DashboardPage = () => {
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/" element={<InnerContent />}>
              <Route path="/" element={<AuthGuard />}>
                <Route path="/app" element={<Navigate replace to="home" />} />
                <Route path="/home" element={<Dashboard />} />
                <Route path="/cover-letter/*" element={<CoverLetter />} />
                <Route path="/resume/*" element={<Resume />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/profile/*" element={<Profile />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Layout>
    </>
  );
};

export default DashboardPage;
