import { Routes, Route, Navigate } from "react-router-dom";
import DashboardPage from "../dashboard";

function DashboardRoutes() {
  console.log("here");
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="home" />} />
      <Route path="/*" element={<DashboardPage />} />
    </Routes>
  );
}
export default DashboardRoutes;
