import React from "react";
import { Route, Routes } from "react-router-dom";
import CoverWriting from "../component/cover-letter";
import CreateCoverLetter from "../component/cover-letter/create/manual";
import CoverDetails from "../component/cover-letter/details";
import CreateCoverLetterType from "../component/cover-letter/create";
import CreateCoverLetterResume from "../component/cover-letter/create/resume";
const CoverLetter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<CoverWriting />} />
        <Route
          path="/create/information/:resumeId?"
          element={<CreateCoverLetter />}
        />
        <Route path="/create/resume" element={<CreateCoverLetterResume />} />
        <Route path="/create" element={<CreateCoverLetterType />} />
        <Route path="/detail/:id" element={<CoverDetails />} />
      </Routes>
    </>
  );
};
export default CoverLetter;
