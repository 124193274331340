import React from "react";
import { Box, Wrapper, QuickActions, QuickBoxes, QuickBox } from "./style";
import { ArrowLeft } from "../../../assets";
import { Link } from "react-router-dom";

const CreateCoverLetterType = () => {
  return (
    <Wrapper>
      <Box>
        <h1 className="heading">
          How do you want to generate your cover letter?
        </h1>
        <p className="sub-text">Select from the options below</p>
      </Box>
      <QuickActions>
        <QuickBoxes>
          <Link to="/dashboard/cover-letter/create/resume">
            <QuickBox>
              <div className="coming">
                <p>Generate with a resume</p>
                <span>
                  Upload a PDF document of your resume to generate a cover
                  letter.
                </span>
              </div>
              <img src={ArrowLeft} alt="data" />
            </QuickBox>
          </Link>
          <Link to="/dashboard/cover-letter/create/information">
            <QuickBox>
              <div className="coming">
                <p>Enter details manually</p>
                <span>
                  Fill the form included with all of your details and we’ll
                  generate a cover letter.
                </span>
              </div>
              <img src={ArrowLeft} alt="data" />
            </QuickBox>
          </Link>
        </QuickBoxes>
      </QuickActions>
    </Wrapper>
  );
};

export default CreateCoverLetterType;
