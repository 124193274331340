import React from "react";
import { Box, Wrapper, QuickActions, QuickBoxes, QuickBox } from "./style";
import { ArrowLeft } from "../../assets";
import { Link } from "react-router-dom";
import { ButtonBack } from "../../utils/util";

const ProfilePage = () => {
  return (
    <Wrapper>
      <Box>
        <ButtonBack />
        <h1 className="heading">My Profile</h1>
        <p className="sub-text">Select from the options below</p>
      </Box>
      <QuickActions>
        <QuickBoxes>
          <Link to="/dashboard/profile/personal-information">
            <QuickBox>
              <div className="coming">
                <p>Update personal information</p>
              </div>
              <img src={ArrowLeft} alt="data" />
            </QuickBox>
          </Link>
          <Link to="/dashboard/profile/pricing">
            <QuickBox>
              <div className="coming">
                <p>Update account pricing</p>
              </div>
              <img src={ArrowLeft} alt="data" />
            </QuickBox>
          </Link>
        </QuickBoxes>
      </QuickActions>
    </Wrapper>
  );
};

export default ProfilePage;
