import React, { useEffect, useState } from "react";
import Loader from "../../component/global/loader";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import Home from "../../component/landing";
import Pricing from "../../component/landing/pricing";
import Layout from "../../component/landing/layout";
import Contact from "../../component/landing/contact";
import Demo from "../../component/landing/demo";
const Content = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9998;
  transform: translateY(100%);
  animation: slideInFromBottom 2s forwards;

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
const Fade = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* animation: fadeOut 36s forwards; */

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;
const Landing = () => {
  const [loading, setLoading] = useState(false);
  // Simulate a delay to hide the loader (you can replace this with actual loading logic)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 12650); // Example: Hide loader after 2 seconds
  }, []);
  return loading ? (
    <Fade>
      <Loader />
    </Fade>
  ) : (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/demo" element={<Demo />} />
      </Routes>
    </Layout>
  );
};

export default Landing;
