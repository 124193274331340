import React from "react";
import CreateFeedback from "../component/dashboard/feedback";
const Feedback = () => {
  return (
    <>
      <CreateFeedback />
    </>
  );
};
export default Feedback;
