import React, { useEffect, useState } from "react";
import { FormInput } from "../../../global/formInput";
import {
  Wrapper,
  Box,
  InputBox,
  ButtonBox,
  FirstWrapper,
  SecondWrapper,
  GeneratedBox,
  BottomBox,
  Pagination,
  ImageBox,
  ButtonSecondBox,
} from "./style";
import { LeftArrow, RightArrow, Retry, Copy, Edit } from "../../../../assets";
import { CustomButton, EditButton } from "../../../global/button";
import Spinner from "../../../global/spinner";
import ReactTyped from "react-typed";
import { alerts } from "../../../../utils/alert";
// @ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import {
  createCoverLetter,
  getPersonalInformation,
} from "../../../../services/coverLetter";
import {
  fixesIssuesWithCoverLetter,
  ReplaceVariables,
} from "../../../../utils";
import {
  ActivityLabels,
  trackButtonClick,
  trackPageView,
} from "../../../../utils/analytics";
import { useAuth } from "../../../../providers/AuthProvider";
import { ButtonBack } from "../../../../utils/util";

const CreateCoverLetter = () => {
  const location = useLocation();
  const [personalInformation, setPersonalInformation] = useState("");
  trackPageView("cover letter manual", location.pathname);
  const [firstName, setFirstName] = useState("");
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const { increaseCount } = useAuth();
  const [companyName, setCompanyName] = useState("");
  const [years, setYears] = useState("");
  const [notable, setNotable] = useState("");
  const [relevant, setRelevant] = useState<string[]>([]);
  const [description, setDescription] = useState("");
  const [copySuccess, setCopySuccess] = useState("Copy to clipboard");
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState<any>([]);
  const [counter, setCounter] = useState(0);
  const [coverId, setCoverId] = useState("");
  const [currentCover, setCurrentCover] = useState("");
  const [payloadSubmitted, setPayloadSubmitted] = useState({});
  const { resumeId } = useParams();
  const [initialTags, setInitialTags] = useState<string[]>([]);
  const [key, setKey] = useState(0);

  useEffect(() => {
    setCurrentCover(result[counter - 1]);
  }, [counter]);

  const incrementCounter = (upperLimit: number) => {
    if (counter < upperLimit) {
      setCounter(counter + 1);
    }
    setCurrentCover(result[counter + 1]);
    setKey((prevKey) => prevKey + 1);
    console.log("Typed Text Updated:", currentCover);
  };
  const decrementCounter = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
    setCurrentCover(result[counter + 1]);
    setKey((prevKey) => prevKey + 1);
    console.log("Typed Text Updated:", currentCover);
  };

  const isDisabled = () => {
    if (resumeId) {
      return !description || !companyName || !role;
    } else {
      return (
        !firstName ||
        !role ||
        !companyName ||
        !years ||
        relevant.length < 1 ||
        !description
      );
    }
  };
  const addCoverToArray = (newItem: string) => {
    setResult((prevResult: any) => [...prevResult, newItem]);
  };

  const CreateCoverLetter = useMutation(createCoverLetter, {
    onSuccess: (data) => {
      alerts.success(
        "Cover Letter Success",
        "Cover Letter generated successfully"
      );
      increaseCount();
      trackButtonClick(ActivityLabels.COVER_LETTER_CREATED);
      addCoverToArray(fixesIssuesWithCoverLetter(data.data.coverLetter));
      setCoverId(data.data.coverId);
      setCounter(counter + 1);
      setTimeout(function () {
        setLoading(false);
        setSubmitted(true);
      }, 1000); // Wait 1 second
    },
    onError: (error: any) => {
      setLoading(false);
      console.log(error, "error");
      alerts.error("Generation Failed", error);
    },
  });
  const onSubmit = () => {
    if (isDisabled()) {
      alerts.error("Error", "Please fill all the fields correctly");
      return;
    }
    let payload: any;
    if (resumeId) {
      payload = {
        resumeId: resumeId,
        employer: companyName,
        jobTitle: role,
        jobDescription: description,
        infoType: "RESUME",
      };
    } else {
      payload = {
        employer: companyName,
        jobTitle: role,
        infoType: "MANUAL",
        jobDescription: description,
        manualInfo: {
          firstName: firstName,
          yearsOfExperience: years,
          skills: relevant.join(", "),
          notableAchievement: notable,
          jobDescription: description,
        },
      };
    }
    setPayloadSubmitted(payload);
    if (coverId) {
      payload.coverId = coverId;
    }
    setLoading(true);
    CreateCoverLetter.mutate(payload);
  };

  useEffect(() => {
    fetchPersonalInformation();
  }, []);
  const fetchPersonalInformation = async () => {
    try {
      const response = await getPersonalInformation();
      setPersonalInformation(response.userInfo);
      setYears(response.userInfo.YOE);
      setNotable(response.userInfo.achievement);
      setFirstName(response.userInfo.name);
      setInitialTags(response.userInfo.skills.split(", "));
      setRelevant(response.userInfo.skills.split(", "));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  function TypedText({ currentCover }: { currentCover: string }) {
    return (
      <ReactTyped
        typeSpeed={5}
        strings={[currentCover]}
        smartBackspace
        shuffle={false}
        backDelay={1}
        fadeOut={false}
        fadeOutDelay={100}
        loopCount={0}
        showCursor
        cursorChar="|"
      />
    );
  }
  const handleChange = (tags: string[]) => {
    setRelevant(tags);
  };
  const [loadingDots, setLoadingDots] = useState(".");
  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingDots((prevDots) =>
        prevDots.length === 3 ? "." : prevDots + "."
      );
    }, 300);
    return () => clearInterval(interval);
  }, []);
  return (
    <Wrapper>
      <Box>
        <ButtonBack />
        <h1 className="heading">Cover Letter</h1>
        <p className="sub-text">
          Fill the form below as clearly as possible to generate a suitable
          cover letter
        </p>
      </Box>
      {!submitted && (
        <FirstWrapper>
          {!resumeId && (
            <InputBox>
              <FormInput
                label="First name"
                index="1"
                type="text"
                value={firstName}
                className="left"
                stateHandler={setFirstName}
                holder="e.g Peter"
              />
              <FormInput
                label="What role is this for?"
                index="2"
                type="text"
                value={role}
                className="right"
                stateHandler={setRole}
                holder="e.g Product Designer"
              />
            </InputBox>
          )}
          {!resumeId && (
            <InputBox>
              <FormInput
                label="Company name"
                index="3"
                type="text"
                value={companyName}
                stateHandler={setCompanyName}
                holder="e.g Spotify"
                className="left"
              />
              <FormInput
                label="Years of experience"
                index="4"
                type="text"
                value={years}
                stateHandler={setYears}
                className="right"
                holder="e.g 3 years"
              />
            </InputBox>
          )}
          {resumeId && (
            <InputBox>
              <FormInput
                label="Company name"
                index={resumeId ? "1" : "3"}
                type="text"
                value={companyName}
                stateHandler={setCompanyName}
                holder="e.g Spotify"
                className="left"
              />
              <FormInput
                label="What role is this for?"
                index="2"
                type="text"
                value={role}
                className="right"
                stateHandler={setRole}
                holder="e.g Product Designer"
              />
            </InputBox>
          )}
          <FormInput
            label="Job Description/ Requirement"
            index={resumeId ? "3" : "5"}
            type="textarea"
            value={description}
            stateHandler={setDescription}
            holder="Write job description"
          />
          {!resumeId && (
            <FormInput
              label="Notable achievements"
              index="6"
              type="textarea"
              value={notable}
              stateHandler={setNotable}
              holder="Write something interesting..."
            />
          )}
          {!resumeId && (
            <FormInput
              label="Relevant skills (To be separated by Enter Key or comma)"
              index="7"
              type="keypad"
              onTagsChange={handleChange}
              initialTags={initialTags}
              holder="Write your skills..."
            />
          )}
          <ButtonBox>
            <CustomButton
              disabled={isDisabled()}
              onClick={onSubmit}
              loading={loading}
            >
              {loading
                ? `Generating Cover Letter ${loadingDots}`
                : "Generate cover letter"}
            </CustomButton>
          </ButtonBox>
        </FirstWrapper>
      )}

      {submitted && (
        <SecondWrapper>
          <p className="text">Generated Cover Letter</p>
          <GeneratedBox>
            {loading && <Spinner text="generating" />}
            {!loading && (
              <ReactTyped
                key={key}
                typeSpeed={0}
                strings={[ReplaceVariables(payloadSubmitted, currentCover)]}
                smartBackspace
                shuffle={false}
                backDelay={0}
                fadeOut={false}
                fadeOutDelay={100}
                loopCount={100}
                showCursor
                cursorChar="-"
              />
            )}
            <BottomBox>
              <Pagination>
                <img onClick={decrementCounter} src={LeftArrow} alt="retry" />
                <p className="text">
                  {counter}/{result.length}
                </p>
                <img
                  onClick={() => incrementCounter(result.length)}
                  src={RightArrow}
                  alt="retry"
                />
              </Pagination>
              <ImageBox onClick={onSubmit}>
                <img src={Retry} alt="retry" />
              </ImageBox>
            </BottomBox>
          </GeneratedBox>

          <ButtonSecondBox>
            <CopyToClipboard
              text={result[counter - 1]}
              onCopy={() => {
                trackButtonClick(ActivityLabels.COPY_TO_CLIPBOARD);
                alerts.success("Copied", "Cover letter Copied");
              }}
            >
              <CustomButton>
                <img src={Copy} alt="retry" />
                {copySuccess}
              </CustomButton>
            </CopyToClipboard>
            {/* <div className="left">
              <EditButton
                onClick={() => {
                  trackButtonClick(ActivityLabels.EDIT_COVER_LETTER);
                  setSubmitted(false);
                }}
              >
                <img src={Edit} alt="retry" />
                Edit details
              </EditButton>
            </div> */}
          </ButtonSecondBox>
        </SecondWrapper>
      )}
    </Wrapper>
  );
};

export default CreateCoverLetter;
